import { styled } from '@mui/material/styles';

export const RegisterStyled = styled('div')(({ theme }) => ({
  '&.page__register': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  '.container': {
    zIndex: '5',
  },
  '.card__register': {
    padding: '40px 0',
  },
  '.card__title': {
    fontFamily: 'Roboto Slab, Times New Roman, serif',
    color: theme.custom.text.title,
    fontWeight: 700,
    marginTop: '10px',
    marginBottom: '12px',
  },
  '.info__area': {
    padding: '20px 0',
  },
}));
