import axios, { AxiosRequestConfig } from 'axios';
import i18n from 'plugins/i18n';

// stores
import store from 'store/configureStore';
import { setError } from 'store/slices/errorSlice';
import { setCredential } from 'store/slices/credentialSlice';
import { setIsRefreshingToken } from 'store/slices/otherSlice';

// libraries
import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import _ from 'lodash';

// others
import { isAliveJWT } from 'utils/token';
import { API_ROUTES } from 'constants/api-routes';
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/common';

axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    config.timeout = Number(process.env.REACT_APP_API_TIMEOUT) || 5000;
    config.headers['Accept-Language'] = i18n?.language;
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
    if (
      !config.baseURL &&
      !config.url?.includes('://') &&
      !config.url?.includes(API_ROUTES.AUTH_REFRESH)
    ) {
      config.baseURL = apiUrl;

      // @ts-ignore
      const accessToken = store.getState()?.credential?.accessToken;
      // @ts-ignore
      const refreshToken = store.getState()?.credential?.refreshToken;

      try {
        const res = await checkAndRefreshToken({
          accessToken,
          refreshToken,
          configURL: config.url,
        });
        // Token was expired & refreshed token successfully.
        if (res) {
          // Only set headers Authorization if request to API.
          setAuthorizationHeader(config, res.data.accessToken);
        } else if (isAliveJWT(accessToken)) {
          // Keep the same header if nothing changed
          setAuthorizationHeader(config, accessToken);
        }
      } catch (err) {
        throw err;
      }
    } else if (
      !config.baseURL &&
      !config.url?.includes('://') &&
      config.url?.includes(API_ROUTES.AUTH_REFRESH)
    ) {
      config.baseURL = apiUrl;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.message === 'Network Error') {
      // server is down for maintenance
    } else if (error?.response && error?.response.status === 401) {
      Cookies.remove(STORAGE_ACCESS_TOKEN_KEY);
      Cookies.remove(STORAGE_REFRESH_TOKEN_KEY);
      store.dispatch(setCredential(null));
    }
    let message = '';
    if (_.isArray(error?.response?.data?.message)) {
      error?.response.data?.message.forEach((m: string, index: number) => {
        message +=
          error?.response.data?.message.length - 1 === index ? m : m + '<br>';
      });
    } else {
      message = error?.response?.data?.message || i18n.t('SERVICE_UNAVAILABLE');
    }
    store.dispatch(
      setError({
        statusCode: error?.response?.status || 503,
        message,
      })
    );
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const checkAndRefreshToken = async ({
  accessToken,
  refreshToken,
  configURL,
  keepAwaitIn = 200,
  maxLoopCounter = 1000,
}: any) => {
  if (
    isAliveJWT(refreshToken) &&
    !isAliveJWT(accessToken) &&
    (!configURL.includes(API_ROUTES.AUTH_REFRESH) ||
      !configURL.includes(API_ROUTES.AUTH_LOGIN))
  ) {
    // Is any other requesting API is refreshing token?
    if (!store.getState()?.other?.isRefreshingToken) {
      // Set the other requesting is waiting
      store.dispatch(setIsRefreshingToken(true));

      try {
        // Request the refresh token on api of next.
        const res = await axios.post(API_ROUTES.AUTH_REFRESH, {
          token: refreshToken,
        });

        const accessObj = decode(res?.data.accessToken) || {};
        const refreshObj = decode(res?.data.refreshToken) || {};
        Cookies.set(STORAGE_ACCESS_TOKEN_KEY, res?.data.accessToken, {
          path: '/',
          secure: true,
          // @ts-ignore
          expires: new Date(accessObj.exp * 1000),
        });
        Cookies.set(STORAGE_REFRESH_TOKEN_KEY, res?.data.refreshToken, {
          path: '/',
          secure: true,
          // @ts-ignore
          expires: new Date(refreshObj.exp * 1000),
        });

        // Update store value and response data on client memory
        store.dispatch(setCredential(res?.data));

        // Clear waiting
        store.dispatch(setIsRefreshingToken(false));
        return { ...res, headers: axios.defaults.headers };
      } catch (err) {
        console.log(err);
        // Clear waiting
        store.dispatch(setIsRefreshingToken(false));
      }
    }

    // Return immidiately if keepAwait = 0
    if (!keepAwaitIn) {
      return;
    }

    // The other requesting API is refreshing token, just waiting here.
    const isLoopChecking = async (ms: number, count: number): Promise<any> => {
      if (count > maxLoopCounter) {
        return;
      }

      // Check refreshing token and waiting
      if (store.getState()?.other?.isRefreshingToken) {
        await new Promise((resolve) => setTimeout(resolve, ms));
        return isLoopChecking(ms, count + 1);
      }
    };
    await isLoopChecking(keepAwaitIn, 0);
  }
};

const setAuthorizationHeader = (
  config: AxiosRequestConfig,
  accessToken: string
) => {
  config.headers.Authorization = `Bearer ${accessToken}`;
};
