import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CredentialType } from 'types/credentialType';

// Define a type for the slice state

// Define the initial state using that type
// @ts-ignore
const initialState: CredentialType = {};

export const credentialSlice = createSlice({
  name: 'credential',
  initialState,
  reducers: {
    // @ts-ignore
    setCredential: (state, action: PayloadAction<CredentialType>) => {
      if (!action.payload) {
        return {};
      }
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCredential } = credentialSlice.actions;

export default credentialSlice;
