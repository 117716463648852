import React, { useContext, useEffect } from 'react';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppSelector } from 'hooks';

// libraries
import useMediaQuery from '@mui/material/useMediaQuery';

// styles
import { SwitchSliderStyled } from 'assets/styled/cstyled/switchSliderStyled';

// others
import { ColorModeContext } from 'contexts/mode';
import { STORAGE_COLOR_MODE } from 'constants/common';

const SwitchSlider = (): JSX.Element => {
  const colorModeContext = useContext(ColorModeContext);

  const colorMode = useAppSelector(
    (state) => state.other.colorMode,
    shallowEqual
  );

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  let currentMode = false;

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_COLOR_MODE)) {
      currentMode = prefersDarkMode;
    } else {
      currentMode =
        localStorage.getItem(STORAGE_COLOR_MODE) === 'dark' ? true : false;
    }
    colorModeContext.toggleColorMode(currentMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  const changeColorMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    colorModeContext.toggleColorMode(e.target.checked ? 'dark' : 'light');
    localStorage.setItem(
      STORAGE_COLOR_MODE,
      e.target.checked ? 'dark' : 'light'
    );
  };

  return (
    <SwitchSliderStyled>
      <input
        type="checkbox"
        checked={colorMode === 'dark'}
        onChange={(e) => changeColorMode(e)}
      />
      <span className="switch--slider switch--slider-round"></span>
    </SwitchSliderStyled>
  );
};

export default React.memo(SwitchSlider);
