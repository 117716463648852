import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ErrorType {
  statusCode: number;
  title?: string;
  message: string | null;
}

// Define the initial state using that type
// @ts-ignore
const initialState: ErrorType = {};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    // @ts-ignore
    setError: (state, action: PayloadAction<ErrorType>) => {
      if (!action.payload) {
        return {};
      }
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setError } = errorSlice.actions;

export default errorSlice;
