import { ThemeOptions } from '@mui/material';
import { CustomTOption } from 'types/customTOptionType';

interface CustomThemeOptions extends ThemeOptions {
  custom?: CustomTOption;
}

export const light: CustomThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#fff',
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  custom: {
    default: '#999',
    background: '#eee',
    primary: '#9c27b0',
    info: '#00acc1',
    success: '#4caf50',
    warning: '#ff9800',
    danger: '#f44336',
    rose: '#e91e63',
    grayIcon: '#495057',
    draw: 'rgba(255, 255, 255, 1)',
    text: {
      title: '#3c4858',
    },
    linear: {
      primaryFirst: '#ab47bc',
      primarySecond: '#8e24aa',
      infoFirst: '#26c6da',
      infoSecond: '#00acc1',
      successFirst: '#66bb6a',
      successSecond: '#43a047',
      warningFirst: '#ffa726',
      warningSecond: '#fb8c00',
      dangerFirst: '#ef5350',
      dangerSecond: '#e53935',
      roseFirst: '#ec407a',
      roseSecond: '#d81b60',
    },
    social: {
      apple: '#000',
      facebook: '#097eeb',
      google: '#d64937',
    },
    hover: {
      nav: '#c8c8c8',
    },
  },
};
