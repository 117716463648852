import { styled } from '@mui/material/styles';

export const SwitchSliderStyled = styled('label')(({ theme }) => ({
  position: 'relative',
  width: '30px',
  height: '18px',
  input: {
    display: 'none',
    '&:checked + .switch--slider': {
      '&::before': {
        backgroundColor: theme.palette.grey[400],
        transform: 'translateX(12px)',
      },
    },
  },
  '.switch--slider': {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.custom.primary,
    transition: '0.4s',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      left: '3px',
      bottom: '3px',
      transition: '0.4s',
      backgroundColor: theme.palette.grey[50],
    },
    '&-round': {
      borderRadius: '9px',
    },
  },
}));
