import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { hexToRgb } from 'assets/styled/variableStyle';

export const CustomDrawerStyled = styled(Drawer)(({ theme }) => ({
  '&.sidebar__inner-minisize .drawer__paper': {
    width: '80px',
  },
  '.drawer__paper': {
    width: '260px',
    '&--inner': {
      transitionProperty: 'top, bottom, width',
      transitionDuration: '0.2s, 0.2s, 0.35s',
      transitionTimingFunction: 'linear, linear, ease',
      color: theme.palette.common.white,
      '&::after': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: theme.palette.common.black,
        opacity: theme.palette.mode === 'light' ? 0.8 : 0.9,
      },
    },
  },
  '.nav__link': {
    flex: 1,
    color: theme.palette.text.primary,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: 1.5,
    display: 'block',
    padding: '15px',
    margin: '0 5px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    '&:hover, &:focus': {
      background: 'rgba(' + hexToRgb(theme.custom.hover.nav) + ', 0.2)',
    },
  },
  '.header__list-item': {
    padding: 0,
    '&-icon': {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: '3px',
    },
    '&-text': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  '.sidebar': {
    '&__background': {
      position: 'absolute',
      zIndex: -5,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      transition: 'all 0.3s linear',
    },
    '&__logo, &__user, &__mnav': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: '1px',
        right: '15px',
        width: 'calc(100% - 30px)',
        backgroundColor:
          'rgba(' + hexToRgb(theme.palette.common.white) + ', 0.3)',
      },
    },
    '&__logo': {
      padding: '22px 22px 15px',
      '&--icon': {
        width: '35px',
        float: 'left',
        marginRight: '10px',
      },
      '&--text': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.common.white
            : theme.palette.grey[400],
        fontSize: '18px',
        textTransform: 'uppercase',
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginLeft: 'auto',
      },
    },
    '&__user': {
      padding: '20px 0',
      '&--avatar': {
        width: '34px',
        height: '34px',
        overflow: 'hidden',
        float: 'left',
        marginLeft: '22px',
        marginRight: '11px',
        borderRadius: '50%',
        boxShadow:
          '0 10px 30px -12px rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.42), 0 4px 25px 0px rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.2)',
      },
      '.sidebar__nav': {
        '&--list': {
          padding: 0,
        },
        '&--link': {
          '&.active': {
            '&, &:focus': {
              background: 'none',
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor:
                'rgba(' + hexToRgb(theme.palette.grey[400]) + ', 0.2)',
              boxShadow: 'none',
            },
          },
          '&-user': {
            marginTop: 0,
            padding: '6px 0',
            '&:hover': {
              background: 'none',
              boxShadow: 'none',
            },
            '&.active': {
              '&, &:hover, &:focus': {
                background: 'none',
                boxShadow: 'none',
              },
            },
          },
        },
      },
    },
    '&__mnav': {
      padding: '20px 0',
      margin: 0,
      '.search--mobile': {
        padding: '0 22px',
        '.input--search': {
          width: 'calc(100% - 43px)',
        },
      },
      '.sidebar__nav': {
        '&--link': {
          '&.active': {
            '&, &:focus': {
              background: 'none',
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor:
                'rgba(' + hexToRgb(theme.palette.grey[400]) + ', 0.2)',
              boxShadow: 'none',
            },
          },
        },
      },
    },
    '&__nav': {
      position: 'relative',
      overflow: 'auto',
      '&--list': {
        paddingTop: '15px',
        '&-child': {
          padding: 0,
        },
      },
      '&--item': {
        display: 'block',
        padding: 0,
      },
      '&--link': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.common.white
            : theme.palette.grey[400],
        display: 'block',
        padding: '10px',
        margin: '10px 15px 0',
        borderRadius: '3px',
        cursor: 'pointer',
        '&:hover, &.sidebar__nav--child-active': {
          backgroundColor:
            'rgba(' + hexToRgb(theme.palette.grey[400]) + ', 0.2)',
          boxShadow: 'none',
        },
        '&.active': {
          '&, &:hover, &:focus': {
            backgroundColor: theme.custom.primary,
            boxShadow:
              '0 12px 20px -10px rgba(' +
              hexToRgb(theme.custom.primary) +
              ', 0.28), 0 4px 20px 0 rgba(' +
              hexToRgb(theme.palette.common.black) +
              ', 0.12), 0 7px 8px -5px rgba(' +
              hexToRgb(theme.custom.primary) +
              ', 0.2)',
          },
        },
      },
      '&--icon': {
        float: 'left',
        marginLeft: '3px',
        marginRight: '15px',
      },
      '&--text': {
        fontSize: '14px',
        fontWeight: 300,
        margin: '0 0 0 auto',
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '&--label': {
        fontSize: '14px',
        fontWeight: 300,
        width: '24px',
        float: 'left',
        marginLeft: '3px',
        marginRight: '15px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
  },
  '.icon__arrow': {
    marginTop: '11px',
    position: 'absolute',
    right: '18px',
    marginLeft: '2px',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    transition: 'all 0.15s ease-in',
    [theme.breakpoints.up('md')]: {
      '&.icon--hidden': {
        display: 'none',
      },
    },
    '&.active': {
      transform: 'rotate(180deg)',
    },
  },
}));
