import { styled } from '@mui/material/styles';

export const HomeStyled = styled('div')(({ theme }) => ({
  '.card': {
    '&__statistic': {
      '.card__header': {
        textAlign: 'right',
        '.card__title': {
          fontSize: '1.825em',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 0,
          small: {
            marginLeft: '5px',
          },
        },
      },
      '.card__icon': {
        '.fab, .fas, .far, .fal, svg, .material-icons': {
          width: '36px',
          height: '36px',
          fontSize: '36px',
          lineHeight: '36px',
          margin: '10px',
        },
      },
    },
    '&__title': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[800]
          : theme.palette.grey[300],
      lineHeight: '1.4em',
      marginLeft: 'auto',
      '&--no-space': {
        marginTop: 0 + '!important',
        marginBottom: '3px',
      },
      '.line__dot': {
        flex: 1,
      },
    },
    '&__footer': {
      padding: '10px 0 0',
      margin: '15px 0 0',
      borderTop:
        '1px solid' +
        (theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[600]),
    },
    '&__category': {
      color: theme.palette.grey[500],
      fontSize: '14px',
      fontWeight: 300,
      paddingTop: '10px',
      margin: '0 0 0 auto',
      '&--content': {
        padding: 0,
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    },
    '&__stats': {
      color: theme.palette.grey[500],
      fontSize: '12px',
      display: 'inline-flex',
      maxWidth: '100%',
      svg: {
        width: '16px',
        height: '16px',
        marginRight: '3px',
      },
      '.fab, .fas, .far, .fal, .material-icons': {
        fontSize: '16px',
        marginRight: '3px',
      },
    },
  },
  '.grid__item': {
    padding: '0 15px',
  },
  '.tbl__has-flag td': {
    '&': {
      minWidth: 'auto',
    },
    '&:first-of-type': {
      width: '40px',
      minWidth: '40px',
    },
  },
  '.icon__up': {
    width: '14px',
    height: '14px',
  },
}));
