import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppSelector } from 'hooks';

// libraries
import PerfectScrollbar from 'perfect-scrollbar';
import _ from 'lodash';

// layouts
import ErrorLayout from 'layouts/ErrorLayout';

// components
import SwitchSlider from 'components/SwitchSlider';
import Sidebar from 'components/Sidebar';

// others
import { APP_ROUTES } from 'constants/app-routes';

let ps: any;

const AuthLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();

  const error = useAppSelector((state) => state.error, shallowEqual);
  const auth = useAppSelector((state) => state.auth, shallowEqual);

  const [delayAuth, setDelayAuth] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setDelayAuth(false);
    }, 500);
  }, []);

  const mainContent = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkPerfectScrollbar = () => {
      if (
        // @ts-ignore
        navigator.userAgentData?.platform.indexOf('Windows') !== -1 &&
        mainContent?.current
      ) {
        // @ts-ignore
        ps = new PerfectScrollbar(mainContent.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = 'hidden';
      } else if (ps) {
        document.body.style.overflow = 'auto';
        ps.destroy();
      }
    };
    checkPerfectScrollbar();
    window.addEventListener('resize', checkPerfectScrollbar);
    return () => {
      // @ts-ignore
      if (navigator.userAgentData?.platform.indexOf('Windows') !== -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener('resize', checkPerfectScrollbar);
    };
  }, []);

  return auth && auth.loggedIn ? (
    <div ref={mainContent} className="layout__element">
      <div className="nbar">
        <SwitchSlider />
      </div>
      <div className="item--over">
        {_.isEmpty(error) ? (
          <div className="inner inner--flex">
            <Sidebar />
            {children}
          </div>
        ) : (
          <ErrorLayout />
        )}
      </div>
    </div>
  ) : delayAuth ? (
    <div></div>
  ) : (
    <Redirect
      to={{
        pathname: APP_ROUTES.LOGIN,
        state: { from: location },
      }}
    />
  );
};

export default React.memo(AuthLayout);
