import { RouteType } from 'types/routeType';
import Home from 'pages/Home';
import Register from 'pages/Register';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/register',
    exact: true,
    component: Register,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  // {
  //   path: '/route-has-child',
  //   exact: false, // set false if use children route
  //   // @ts-ignore
  //   component: RouteHasChild,
  //   routes: [
  //     {
  //       path: '/route-has-child/route-child*',
  //       exact: true,
  //       component: RouteChild,
  //     },
  //     {
  //       path: '/route-has-child/*',
  //       exact: false,
  //       component: NotFound,
  //     },
  //   ],
  // },
  {
    path: '*',
    exact: false,
    component: NotFound,
  },
];

export default routes;
