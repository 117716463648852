import React from 'react';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppSelector } from 'hooks';

// libraries
import classNames from 'classnames';

// components
import Head from 'components/Head';
import Footer from 'components/Footer';

// styles
import { PanelStyled } from 'assets/styled/cstyled/panelStyled';

const Panel = ({
  children,
  ...rest
}: {
  children: JSX.Element;
}): JSX.Element => {
  const miniSidebar = useAppSelector(
    (state) => state.other.isMiniSidebar,
    shallowEqual
  );

  const panelClasses = classNames({
    panel__element: true,
    'sidebar--minisize': miniSidebar,
  });

  return (
    // @ts-ignore
    <PanelStyled className={panelClasses}>
      {/* @ts-ignore */}
      <Head {...rest} className="header" />
      <div className="main">{children}</div>
      <Footer />
    </PanelStyled>
  );
};

export default React.memo(Panel);
