import React from 'react';

// styles
import { LoadingStyled } from 'assets/styled/cstyled/loadingStyled';

const Loading = (): JSX.Element => {
  return (
    <div className="page--full-relative">
      <LoadingStyled className="ispinner ispinner--animating">
        {Array(12)
          .fill(0)
          .map((_, index) => (
            <span key={index} className="ispinner__blade"></span>
          ))}
      </LoadingStyled>
      {/* <LoadingStyled className="bubble bubble--spinner">
        <div className="bubble__pulse"></div>
      </LoadingStyled> */}
    </div>
  );
};

export default React.memo(Loading);
