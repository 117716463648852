import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// store
import { setCredential } from 'store/slices/credentialSlice';
import { setError } from 'store/slices/errorSlice';

// libraries
import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import AppleLoginWithButton from 'react-apple-login';
// @ts-ignore
import FacebookLoginWithButton from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLoginWithButton from 'react-google-login';
import momentTZ from 'moment-timezone';
import _ from 'lodash';

// services
import * as userService from 'services/user.service';

// utils
import { customToast } from 'utils/toast';

// layouts
import DefaultLayout from 'layouts/DefaultLayout';

// components
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import Email from '@mui/icons-material/Email';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';

// styles
import { LoginStyled } from 'assets/styled/pstyled/loginStyled';

// interfaces
import { CredentialType } from 'types/credentialType';
import { ILoginFormInputs } from 'types/formType';
import { UserFullInfoType } from 'types/userType';

// others
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/common';

// others
import bgLogin from 'assets/images/background/background-first.jpg';

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const error = useAppSelector((state) => state.error, shallowEqual);
  const auth = useAppSelector((state) => state.auth, shallowEqual);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup.string().required(t('EMAIL_REQUIRED')).email(t('EMAIL_INVALID')),
    password: yup
      .string()
      .required(t('PASSWORD_REQUIRED'))
      .min(5, t('PASSWORD_INVALID')),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
  } = useForm<ILoginFormInputs>({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  // @ts-ignore
  const { from } = location.state || { from: { pathname: '/' } };

  const saveCookie = (data: CredentialType) => {
    const accessObj = decode(data.accessToken) || {};
    const refreshObj = decode(data.refreshToken) || {};
    Cookies.set(STORAGE_ACCESS_TOKEN_KEY, data.accessToken, {
      path: '/',
      secure: true,
      // @ts-ignore
      expires: new Date(accessObj.exp * 1000),
    });
    Cookies.set(STORAGE_REFRESH_TOKEN_KEY, data.refreshToken, {
      path: '/',
      secure: true,
      // @ts-ignore
      expires: new Date(refreshObj.exp * 1000),
    });
  };

  const saveAuth = (data: UserFullInfoType) => {
    saveCookie({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
    });
    dispatch(
      setCredential({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    );
  };

  const doLogin = async (data: ILoginFormInputs) => {
    if (!isSubmiting) {
      if (!_.isEmpty(error)) {
        dispatch(setError(null));
      }
      setIsSubmiting(true);
      try {
        const res = await userService.login(data);
        saveAuth(res);
        history.replace(from);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          customToast('error', err.response?.data?.message);
        } else {
          customToast('error', t('UNKNOWN_ERROR'));
        }
        setIsSubmiting(false);
      }
    }
  };

  const appleCallback = async (response: any) => {
    if (!_.isEmpty(response.error)) {
      return customToast(
        'error',
        t('AUTHENTICATION_FAIL', { social: 'Apple' })
      );
    }
    generalCallback('apple', response.authorization?.id_token);
  };

  const facebookCallback = async (response: any) => {
    if (response.status) {
      return customToast(
        'error',
        t('AUTHENTICATION_FAIL', { social: 'Facebook' })
      );
    }
    generalCallback('facebook', response.accessToken);
  };

  const googleCallback = (response: any) => {
    if (
      !_.isEmpty(response.error) &&
      response.error === 'idpiframe_initialization_failed'
    ) {
      return;
    } else if (!_.isEmpty(response.error)) {
      return customToast(
        'error',
        t('AUTHENTICATION_FAIL', { social: 'Google' })
      );
    }
    generalCallback('google', response.tokenId);
  };

  const generalCallback = async (type: string, token: string) => {
    if (!_.isEmpty(error)) {
      dispatch(setError(null));
    }
    try {
      const res = await userService.social({
        type,
        token,
        timezone: momentTZ.tz.guess(true),
      });
      saveAuth(res);
      history.replace(from);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        customToast('error', err.response?.data?.message);
      } else {
        customToast('error', t('UNKNOWN_ERROR'));
      }
    }
  };

  return auth && !auth.loggedIn ? (
    <DefaultLayout backgroundImage={bgLogin}>
      <LoginStyled className="page page__login page--padding">
        <div className="container">
          <Grid container justifyContent="center" className="grid">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <div className="card card__specific card__login">
                <form onSubmit={handleSubmit(doLogin)}>
                  <div className="card__header card__header--primary">
                    <h4 className="card__title">{t('LOGIN')}</h4>
                    <div className="list__social">
                      {
                        // @ts-ignore
                        process.env.REACT_APP_APPLE_CLIENT_ID ? (
                          <AppleLoginWithButton
                            // @ts-ignore
                            clientId={
                              // @ts-ignore
                              process.env.REACT_APP_APPLE_CLIENT_ID
                            }
                            responseType="code id_token"
                            responseMode="fragment"
                            // @ts-ignore
                            redirectURI={
                              // @ts-ignore
                              process.env.REACT_APP_APPLE_REDIRECT_URL
                            }
                            usePopup={true}
                            render={(renderProps: any) => (
                              <CustomButton
                                justIcon
                                color="transparent"
                                disabled
                                className="btn--icon-button"
                                onClick={renderProps.onClick}
                              >
                                <i className="fab fa-apple" />
                              </CustomButton>
                            )}
                            callback={appleCallback}
                          />
                        ) : (
                          ''
                        )
                      }
                      {
                        // @ts-ignore
                        process.env.REACT_APP_FB_CLIENT_ID ? (
                          <FacebookLoginWithButton
                            // @ts-ignore
                            appId={process.env.REACT_APP_FB_CLIENT_ID}
                            autoLoad={false}
                            fields="name,first_name,last_name,email,picture"
                            render={(renderProps: any) => (
                              <CustomButton
                                justIcon
                                color="transparent"
                                className="btn--icon-button"
                                onClick={renderProps.onClick}
                              >
                                <i className="fab fa-facebook" />
                              </CustomButton>
                            )}
                            callback={facebookCallback}
                          />
                        ) : (
                          ''
                        )
                      }
                      {
                        // @ts-ignore
                        process.env.REACT_APP_GG_CLIENT_ID ? (
                          <GoogleLoginWithButton
                            // @ts-ignore
                            clientId={process.env.REACT_APP_GG_CLIENT_ID}
                            cookiePolicy={'single_host_origin'}
                            render={(renderProps: any) => (
                              <CustomButton
                                justIcon
                                color="transparent"
                                className="btn--icon-button"
                                onClick={renderProps.onClick}
                              >
                                <i className="fab fa-google-plus-g" />
                              </CustomButton>
                            )}
                            onSuccess={googleCallback}
                            onFailure={googleCallback}
                          />
                        ) : (
                          ''
                        )
                      }
                    </div>
                  </div>
                  <p className="txt__description txt--center">
                    {t('OR_BE_CLASSICAL')}
                  </p>
                  <div className="card__body">
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={dirtyFields.email && errors.email ? true : false}
                      success={
                        dirtyFields.email && !errors.email ? true : false
                      }
                      helpText={errors.email?.message}
                      inputProps={{
                        ...register('email'),
                        placeholder: t('EMAIL'),
                        type: 'email',
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      error={
                        dirtyFields.password && errors.password ? true : false
                      }
                      success={
                        dirtyFields.password && !errors.password ? true : false
                      }
                      helpText={errors.password?.message}
                      inputProps={{
                        ...register('password'),
                        placeholder: t('PASSWORD'),
                        type: 'password',
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>lock_utline</Icon>
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                    />
                  </div>
                  <div className="card__footer txt--center">
                    <CustomButton
                      round
                      type="submit"
                      color="primary"
                      className={`${
                        !isValid || isSubmiting ? 'btn--disabled' : ''
                      }`}
                    >
                      {t('LOGIN')}
                    </CustomButton>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </LoginStyled>
    </DefaultLayout>
  ) : (
    <Redirect to={{ pathname: from.pathname }} />
  );
};

export default React.memo(Login);
