import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

export const HeadStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  color: theme.custom.grayIcon,
  position: 'static',
  boxShadow: 'none',
  padding: '10px 0',
  '.toolbar': {
    [theme.breakpoints.up('md')]: {
      paddingRight: '78px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  '.title': {
    color: 'inherit',
    fontWeight: 300,
    fontSize: '18px',
    textTransform: 'none',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    span: {
      [theme.breakpoints.up('md')]: {
        padding: '12px 30px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '12px',
      },
    },
  },
}));
