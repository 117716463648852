import { styled } from '@mui/material/styles';

export const InfoAreaStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: '360px',
  margin: '0 auto',
  '&.info__area': {
    '&--vertical': {
      flexDirection: 'column',
      '.icon__wrapper': {
        marginRight: 0,
      },
    },
    '.info__area--title': {
      fontFamily: 'Roboto Slab, Times New Roman, serif',
      color: theme.custom.text.title,
      fontWeight: 700,
      marginTop: '5px',
      marginBottom: '14px',
      minHeight: 'unset',
    },
    '.info__area--description': {
      color: theme.custom.default,
    },
  },
  '.icon__wrapper': {
    marginRight: '10px',
  },
  '.icon': {
    width: '2.25rem',
    height: '2.25rem',
    fontSize: '2.25rem',
    '&--vertical': {
      width: '61px',
      height: '61px',
    },
    '&--primary': {
      color: theme.custom.primary,
    },
    '&--info': {
      color: theme.custom.info,
    },
    '&--success': {
      color: theme.custom.success,
    },
    '&--warning': {
      color: theme.custom.warning,
    },
    '&--danger': {
      color: theme.custom.danger,
    },
    '&--rose': {
      color: theme.custom.rose,
    },
    '&--gray': {
      color: theme.custom.default,
    },
  },
}));
