import { styled } from '@mui/material/styles';

export const FooterStyled = styled('footer')(({ theme }) => ({
  padding: '15px 0',
  '&': {
    [theme.breakpoints.down('lg')]: {
      '.container': {
        maxWidth: 'none',
      },
    },
  },
  '.footer': {
    '&__nav': {
      marginLeft: '-15px',
      marginRight: '-15px',
      [theme.breakpoints.up('sm')]: {
        float: 'left',
      },
      '&--item': {
        width: 'auto',
        padding: 0,
      },
      '&--link': {
        color: theme.palette.common.white,
        fontSize: '12px',
        fontWeight: 500,
        textTransform: 'uppercase',
        display: 'block',
        padding: '15px',
        borderRadius: '3px',
      },
      '.footer__list': {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
    '&__list': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: 0,
    },
  },
  '.copyright': {
    color: theme.palette.common.white,
    padding: '13px 0',
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      float: 'right',
    },
    span: {
      paddingLeft: '15px',
      paddingRight: '15px',
      display: 'block',
      textAlign: 'center',
    },
  },
}));
