import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// languages
import commonEn from 'locales/en/common.json';
import commonVi from 'locales/vi/common.json';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    interpolation: { escapeValue: true }, // default true, prevent XSS attacks
    // lng: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en', // set language default
    resources: {
      en: {
        common: commonEn,
      },
      vi: {
        common: commonVi,
      },
    },
    fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en', // default dev
    ns: 'common', // default translation
    defaultNS: 'common', // set default for useTranslate no need a param
    load: 'languageOnly', // load main language en-US => en
    nonExplicitSupportedLngs: true, // support main language en-US => en
    // backend: {
    //   // use to load language from backend server
    //   loadPath: `${
    //     process.env.REACT_APP_API_URL || 'https://localhost:8080'
    //   }/locales/{{lng}}/{{ns}}`,
    // },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
