import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// hooks
import { useAppDispatch } from 'hooks';

// stores
import { setCredential } from 'store/slices/credentialSlice';

// libraries
import Cookies from 'js-cookie';

// services
import * as userService from 'services/user.service';

// components
import Hidden from '@mui/material/Hidden';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import Search from '@mui/icons-material/Search';
import Dashboard from '@mui/icons-material/Dashboard';
import Notifications from '@mui/icons-material/Notifications';
import Person from '@mui/icons-material/Person';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';

// styles
import { NavbarStyled } from 'assets/styled/cstyled/navbarStyled';

// others
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/common';

const Navbar = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [unreadNotification, setUnreadNotification] = useState<number>(24);

  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const anchorRefNotification = React.useRef<HTMLButtonElement>(null);

  const [isOpenPerson, setIsOpenPerson] = useState<boolean>(false);
  const anchorRefPerson = React.useRef<HTMLButtonElement>(null);

  const [isLogingOut, setIsLogingOut] = useState<boolean>(false);

  const handleToggleNotification = () => {
    setIsOpenNotification((prevOpen) => !prevOpen);
  };
  const handleCloseNotification = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefNotification.current &&
      anchorRefNotification.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setIsOpenNotification(false);
  };

  const handleTogglePerson = () => {
    setIsOpenPerson((prevOpen) => !prevOpen);
  };
  const handleClosePerson = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRefPerson.current &&
      anchorRefPerson.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setIsOpenPerson(false);
  };

  const onLogout = async (event: Event | React.SyntheticEvent) => {
    handleClosePerson(event);
    if (!isLogingOut) {
      setIsLogingOut(true);
      try {
        await userService.logout();
        Cookies.remove(STORAGE_ACCESS_TOKEN_KEY);
        Cookies.remove(STORAGE_REFRESH_TOKEN_KEY);
        dispatch(setCredential(null));
      } catch (err: any) {
        setIsLogingOut(false);
      }
    }
  };

  return (
    <NavbarStyled className="navbar">
      <CustomInput
        formControlProps={{
          className: 'input--search',
        }}
        inputProps={{
          placeholder: t('SEARCH'),
        }}
      />
      <CustomButton color="white" justIcon round>
        <Search />
      </CustomButton>
      <CustomButton color="transparent" justIcon>
        <Dashboard />
        <Hidden mdUp>
          <span>{t('DASHBOARD')}</span>
        </Hidden>
      </CustomButton>
      <div className="manager">
        <CustomButton
          ref={anchorRefNotification}
          color="transparent"
          justIcon
          onClick={handleToggleNotification}
        >
          <Notifications />
          <span className="number__notification--icon">
            {unreadNotification}
          </span>
          <Hidden mdUp>
            <span onClick={handleToggleNotification}>{t('NOTIFICATIONS')}</span>
          </Hidden>
        </CustomButton>
        <Popper
          open={isOpenNotification}
          anchorEl={anchorRefNotification.current}
          transition
          disablePortal
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Paper className="dropdown">
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className="dropdown__item"
                    >
                      {t('TEMP_NOTIFICATION', { number: unreadNotification })}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className="manager">
        <CustomButton
          ref={anchorRefPerson}
          color="transparent"
          justIcon
          onClick={handleTogglePerson}
        >
          <Person />
          <Hidden mdUp>
            <span onClick={handleTogglePerson}>{t('PERSON')}</span>
          </Hidden>
        </CustomButton>
        <Popper
          open={isOpenPerson}
          anchorEl={anchorRefPerson.current}
          transition
          disablePortal
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Paper className="dropdown">
                <ClickAwayListener onClickAway={handleClosePerson}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleClosePerson}
                      className="dropdown__item"
                    >
                      {t('PROFILE')}
                    </MenuItem>
                    <MenuItem
                      onClick={handleClosePerson}
                      className="dropdown__item"
                    >
                      {t('SETTINGS')}
                    </MenuItem>
                    <Divider light />
                    <MenuItem onClick={onLogout} className="dropdown__item">
                      {t('LOGOUT')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </NavbarStyled>
  );
};

export default React.memo(Navbar);
