import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'App';

// store
import store from 'store/configureStore';

// plugins
import 'plugins/i18n';
import 'plugins/axios';

// styles
import 'assets/scss/styles.scss';

// others
import reportWebVitals from 'reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// console.log(
//   '%c\n\n██╗  ██╗ ██╗   ██╗ ██╗   ██╗ ██████╗   █████╗  ███████╗ ██╗  ██╗ ██╗\n╚██╗██╔╝ ██║   ██║ ██║   ██║ ██╔══██║ ██╔══██╗ ██╔════╝ ██║  ██║ ██║\n ╚███╔╝  ██║   ██║ ██║   ██║ ██║  ██║ ███████║ ██║      ███████║ ██║\n   █╔╝   ██║   ██║ ██║   ██║ ██║  ██║ ██╔══██║ ██║      ██╔══██║ ██║\n   █║    ╚██████╔╝ ╚██████╔╝ ██████╔╝ ██║  ██║ ███████╗ ██║  ██║ ██║\n   ╚╝     ╚═════╝   ╚═════╝  ╚═════╝  ╚═╝  ╚═╝ ╚══════╝ ╚═╝  ╚═╝ ╚═╝\n\n',
//   'font-family: monospace; color:#1f8af5; font-size:12px;'
// );

console.log(
  '%c\n\n\u2588\u2588\u2557  \u2588\u2588\u2557 \u2588\u2588\u2557   \u2588\u2588\u2557 \u2588\u2588\u2557   \u2588\u2588\u2557 \u2588\u2588\u2588\u2588\u2588\u2588\u2557   \u2588\u2588\u2588\u2588\u2588\u2557  \u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557 \u2588\u2588\u2557  \u2588\u2588\u2557 \u2588\u2588\u2557\n\u255A\u2588\u2588\u2557\u2588\u2588\u2554\u255D \u2588\u2588\u2551   \u2588\u2588\u2551 \u2588\u2588\u2551   \u2588\u2588\u2551 \u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2551 \u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2557 \u2588\u2588\u2554\u2550\u2550\u2550\u2550\u255D \u2588\u2588\u2551  \u2588\u2588\u2551 \u2588\u2588\u2551\n \u255A\u2588\u2588\u2588\u2554\u255D  \u2588\u2588\u2551   \u2588\u2588\u2551 \u2588\u2588\u2551   \u2588\u2588\u2551 \u2588\u2588\u2551  \u2588\u2588\u2551 \u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2551 \u2588\u2588\u2551      \u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2551 \u2588\u2588\u2551\n   \u2588\u2554\u255D   \u2588\u2588\u2551   \u2588\u2588\u2551 \u2588\u2588\u2551   \u2588\u2588\u2551 \u2588\u2588\u2551  \u2588\u2588\u2551 \u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2551 \u2588\u2588\u2551      \u2588\u2588\u2554\u2550\u2550\u2588\u2588\u2551 \u2588\u2588\u2551\n   \u2588\u2551    \u255A\u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D \u255A\u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D \u2588\u2588\u2588\u2588\u2588\u2588\u2554\u255D \u2588\u2588\u2551  \u2588\u2588\u2551 \u2588\u2588\u2588\u2588\u2588\u2588\u2588\u2557 \u2588\u2588\u2551  \u2588\u2588\u2551 \u2588\u2588\u2551\n   \u255A\u255D     \u255A\u2550\u2550\u2550\u2550\u2550\u255D   \u255A\u2550\u2550\u2550\u2550\u2550\u255D  \u255A\u2550\u2550\u2550\u2550\u2550\u255D  \u255A\u2550\u255D  \u255A\u2550\u255D \u255A\u2550\u2550\u2550\u2550\u2550\u2550\u255D \u255A\u2550\u255D  \u255A\u2550\u255D \u255A\u2550\u255D\n\n',
  'font-family: monospace; color:#1f8af5; font-size:12px;'
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
