import React, { Suspense, useMemo, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppSelector, useAppDispatch } from 'hooks';

// routes
import routes from 'routes';

// libraries
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';

// stores
import { setColorMode } from 'store/slices/otherSlice';
import { setError } from 'store/slices/errorSlice';
import { setCredential } from 'store/slices/credentialSlice';
import { setAuth } from 'store/slices/authSlice';

// components
import Loading from 'components/Loading';

// styles
import { BaseStyled } from 'assets/styled/commonStyled';

// interfaces
import { RouteType } from 'types/routeType';

// styles
import 'react-toastify/dist/ReactToastify.css';

// others
import { ColorModeContext } from 'contexts/mode';
import { light, dark } from 'configs/theme';
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/common';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = (): JSX.Element => {
  const mode = useAppSelector((state) => state.other.colorMode, shallowEqual);

  const dispatch = useAppDispatch();

  const colorMode = useMemo(
    () => ({
      toggleColorMode: (mode: 'light' | 'dark') => {
        dispatch(setColorMode(mode));
      },
    }),
    [dispatch]
  );

  const theme = useMemo(
    () => createTheme(mode === 'light' ? light : dark),
    [mode]
  );

  const credential = useAppSelector((state) => state.credential, shallowEqual);

  useEffect(() => {
    return () => {
      dispatch(setError(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(credential)) {
      dispatch(
        setAuth({
          loggedIn: true,
        })
      );
    } else {
      dispatch(
        setAuth({
          loggedIn: false,
        })
      );
    }
  }, [credential, dispatch]);

  useEffect(() => {
    const cookieAccess = Cookies.get(STORAGE_ACCESS_TOKEN_KEY);
    const cookieRefresh = Cookies.get(STORAGE_REFRESH_TOKEN_KEY);
    if (cookieAccess || cookieRefresh) {
      dispatch(
        setCredential({
          accessToken: cookieAccess,
          refreshToken: cookieRefresh,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const checkPerfectScrollbar = () => {
      if (
        // @ts-ignore
        navigator.userAgentData?.platform.indexOf('Windows') !== -1
      ) {
        document.body.classList.add('perfect-scrollbar');
      } else {
        document.body.classList.remove('perfect-scrollbar');
      }
    };
    checkPerfectScrollbar();
    window.addEventListener('resize', checkPerfectScrollbar);
    return () => {
      window.removeEventListener('resize', checkPerfectScrollbar);
    };
  }, []);

  const routePage = (routes: RouteType[]) => {
    let result;
    if (routes.length) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={() => (
              // pass the sub-routes down to keep nesting
              // @ts-ignore
              <route.component {...route} routes={route.routes} />
            )}
          />
        );
      });
    }

    return <Switch>{result}</Switch>;
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <ScrollToTop />
            <BaseStyled>
              <div className="layout">{routePage(routes)}</div>
            </BaseStyled>
          </Suspense>
        </BrowserRouter>
        <ToastContainer theme={mode} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
