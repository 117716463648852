import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import otherSlice from 'store/slices/otherSlice';
import errorSlice from 'store/slices/errorSlice';
import credentialSlice from 'store/slices/credentialSlice';
import authSlice from 'store/slices/authSlice';

const store = configureStore({
  reducer: {
    other: otherSlice.reducer,
    error: errorSlice.reducer,
    credential: credentialSlice.reducer,
    auth: authSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
