import { styled } from '@mui/material/styles';
import { hexToRgb } from 'assets/styled/variableStyle';

export const LayoutMaskStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      'rgba(' +
      hexToRgb(theme.palette.common.black) +
      ', ' +
      (theme.palette.mode === 'light' ? '0.5' : '0.8') +
      ')',
  },
}));
