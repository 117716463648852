import { styled } from '@mui/material/styles';

export const NavbarStyled = styled('div')(({ theme }) => ({
  '.input--search': {
    margin: '10px 0',
  },
  '.manager': {
    display: 'inline-block',
  },
  '.number__notification--icon': {
    position: 'absolute',
    top: '5px',
    border: '1px solid ' + theme.palette.common.white,
    right: '5px',
    fontSize: '9px',
    backgroundColor: theme.custom.primary,
    color: theme.palette.common.white,
    minWidth: '16px',
    height: '16px',
    borderRadius: '10px',
    textAlign: 'center',
    lineHeight: 1.5,
    display: 'block',
  },
}));
