import React, { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// hooks
import { useAppSelector, useAppDispatch } from 'hooks';

// stores
import { setCredential } from 'store/slices/credentialSlice';
import { setIsNavMobileOpen } from 'store/slices/otherSlice';

// libraries
import Cookies from 'js-cookie';
import PerfectScrollbar from 'perfect-scrollbar';
import classNames from 'classnames';

// services
import * as userService from 'services/user.service';

// components
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
import Search from '@mui/icons-material/Search';
import Notifications from '@mui/icons-material/Notifications';
import CustomInput from 'components/CustomInput';
import CustomButton from 'components/CustomButton';

// styles
import { CustomDrawerStyled } from 'assets/styled/cstyled/customDrawerStyled';

// interfaces
import { NavType } from 'types/navType';

// others
import { APP_ROUTES } from 'constants/app-routes';
import { NAV_SIDEBAR } from 'constants/nav';
import bgSidebar from 'assets/images/sidebar/background.jpg';
import logo from 'assets/images/common/logo-white.svg';

// others
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/common';

let ps: any;

const SidebarInner = ({
  user,
  headerLinks,
  links,
  className,
}: {
  user?: any;
  headerLinks?: any;
  links?: any;
  className?: string;
}): JSX.Element => {
  const sidebarContent = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkPerfectScrollbar = () => {
      if (
        // @ts-ignore
        navigator.userAgentData?.platform.indexOf('Windows') !== -1 &&
        sidebarContent?.current
      ) {
        // @ts-ignore
        ps = new PerfectScrollbar(sidebarContent.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
      } else if (ps) {
        ps.destroy();
      }
    };
    checkPerfectScrollbar();
    window.addEventListener('resize', checkPerfectScrollbar);
    return () => {
      // @ts-ignore
      if (navigator.userAgentData?.platform.indexOf('Windows') !== -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener('resize', checkPerfectScrollbar);
    };
  }, []);

  return (
    <div className={className} ref={sidebarContent}>
      {user}
      {headerLinks}
      {links}
    </div>
  );
};

const MobileNav = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ul className="sidebar__mnav">
      <ListItem className="sidebar__nav--item">
        <div className="search--mobile">
          <CustomInput
            formControlProps={{
              className: 'input--search',
            }}
            inputProps={{
              placeholder: t('SEARCH'),
            }}
          />
          <CustomButton color="white" justIcon round>
            <Search />
          </CustomButton>
        </div>
      </ListItem>
      <ListItem className="sidebar__nav--item">
        <NavLink to={APP_ROUTES.NOTIFICATIONS} className="sidebar__nav--link">
          <Notifications className="sidebar__nav--icon" />
          <ListItemText
            primary={t('NOTIFICATIONS')}
            disableTypography={true}
            className="sidebar__nav--text"
          />
        </NavLink>
      </ListItem>
    </ul>
  );
};

const Sidebar = (): JSX.Element => {
  const { t } = useTranslation();

  const miniSidebar = useAppSelector(
    (state) => state.other.isMiniSidebar,
    shallowEqual
  );
  const navMobile = useAppSelector(
    (state) => state.other.isNavMobileOpen,
    shallowEqual
  );
  const dispatch = useAppDispatch();

  const initStateCollapse = {
    userCollapse: false,
  };
  NAV_SIDEBAR.forEach((nav) => {
    if (nav.state) {
      // @ts-ignore
      initStateCollapse[nav.state] = false;
    }
  });

  const [stateCollapse, setStateCollapse] = useState(initStateCollapse);

  const [isLogingOut, setIsLogingOut] = useState<boolean>(false);

  const onLogout = async () => {
    if (!isLogingOut) {
      setIsLogingOut(true);
      try {
        await userService.logout();
        Cookies.remove(STORAGE_ACCESS_TOKEN_KEY);
        Cookies.remove(STORAGE_REFRESH_TOKEN_KEY);
        dispatch(setCredential(null));
      } catch (err: any) {
        setIsLogingOut(false);
      }
    }
  };

  const brandElement = (
    <div className="sidebar__logo">
      <Link to={APP_ROUTES.HOME} className="sidebar__logo--link clearfix">
        <img src={logo} alt={t('BRAND')} className="sidebar__logo--icon" />
        <span className="sidebar__logo--text">{t('BRAND')}</span>
      </Link>
    </div>
  );

  const userElement = (
    <div className="sidebar__user clearfix">
      <Avatar src="/broken-image.jpg" className="sidebar__user--avatar" />
      <List className="sidebar__nav--list">
        <ListItem className="sidebar__nav--item">
          <div
            className="sidebar__nav--link sidebar__nav--link-user"
            onClick={() => {
              onCollapse('userCollapse');
            }}
          >
            <ListItemText
              primary="Anonymous"
              secondary={
                <b
                  className={classNames({
                    icon__arrow: true,
                    // @ts-ignore
                    active: stateCollapse['userCollapse'],
                    'icon--hidden': miniSidebar,
                  })}
                />
              }
              disableTypography={true}
              className="sidebar__nav--text"
            />
          </div>
          <Collapse
            in={stateCollapse['userCollapse']}
            unmountOnExit
            style={{ width: '100%' }}
          >
            <List className="sidebar__nav--list-child">
              <ListItem className="sidebar__nav--item">
                <NavLink to={'#'} className="sidebar__nav--link">
                  <span className="sidebar__nav--label">P</span>
                  <ListItemText
                    primary={t('PROFILE')}
                    disableTypography={true}
                    className="sidebar__nav--text"
                  />
                </NavLink>
              </ListItem>
              <ListItem className="sidebar__nav--item">
                <NavLink to={'#'} className="sidebar__nav--link">
                  <span className="sidebar__nav--label">S</span>
                  <ListItemText
                    primary={t('SETTINGS')}
                    disableTypography={true}
                    className="sidebar__nav--text"
                  />
                </NavLink>
              </ListItem>
              <ListItem className="sidebar__nav--item" onClick={onLogout}>
                <div className="sidebar__nav--link">
                  <span className="sidebar__nav--label">L</span>
                  <ListItemText
                    primary={t('LOGOUT')}
                    disableTypography={true}
                    className="sidebar__nav--text"
                  />
                </div>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  );

  const isChildActive = (routes: any[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && isChildActive(routes[i].child)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const createLinks = (routes: NavType[]) => {
    return routes.map((route, index) => {
      if (route.collapse) {
        return (
          <ListItem
            key={index}
            className={classNames({
              'sidebar__nav--item': true,
            })}
          >
            <div
              className={classNames({
                'sidebar__nav--link': true,
                // @ts-ignore
                'sidebar__nav--collapsed': stateCollapse[route.state],
                // @ts-ignore
                'sidebar__nav--child-active': isChildActive(route?.child),
                clearfix: true,
              })}
              onClick={() => {
                // @ts-ignore
                onCollapse(route.state);
              }}
            >
              {route.icon ? (
                typeof route.icon === 'string' ? (
                  <Icon className="sidebar__nav--icon">{route.icon}</Icon>
                ) : (
                  <route.icon className="sidebar__nav--icon" />
                )
              ) : (
                <span className="sidebar__nav--label">{route.label}</span>
              )}
              <ListItemText
                primary={t(route.text)}
                secondary={
                  <b
                    className={classNames({
                      icon__arrow: true,
                      // @ts-ignore
                      active: stateCollapse[route.state],
                      'icon--hidden': miniSidebar,
                    })}
                  />
                }
                disableTypography={true}
                className="sidebar__nav--text"
              />
            </div>
            {/* @ts-ignore */}
            <Collapse in={stateCollapse[route.state]} unmountOnExit>
              <List className="sidebar__nav--list-child">
                {/* @ts-ignore */}
                {createLinks(route.child)}
              </List>
            </Collapse>
          </ListItem>
        );
      }

      return (
        <ListItem key={index} className="sidebar__nav--item">
          <NavLink
            to={route.path || '#'}
            className="sidebar__nav--link clearfix"
          >
            {route.icon !== undefined ? (
              typeof route.icon === 'string' ? (
                <Icon className="sidebar__nav--icon">{route.icon}</Icon>
              ) : (
                <route.icon className="sidebar__nav--icon" />
              )
            ) : (
              <span className="sidebar__nav--label">{route.label}</span>
            )}
            <ListItemText
              primary={t(route.text)}
              disableTypography={true}
              className="sidebar__nav--text"
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  const links = (
    <List className="sidebar__nav--list">{createLinks(NAV_SIDEBAR)}</List>
  );
  const handleDrawerMobileToggle = () => {
    dispatch(setIsNavMobileOpen(!navMobile));
  };

  const onCollapse = (type: string) => {
    setStateCollapse((prevCollapse) => ({
      ...prevCollapse,
      // @ts-ignore
      [type]: !prevCollapse[type],
    }));
  };

  return (
    <div className="sidebar">
      <div>
        <Hidden mdUp>
          <CustomDrawerStyled
            variant="temporary"
            anchor="right"
            open={navMobile}
            classes={{
              paper: 'drawer__paper drawer__paper--inner',
            }}
            ModalProps={{
              keepMounted: true,
            }}
            className="sidebar__inner"
            onClose={handleDrawerMobileToggle}
          >
            {brandElement}
            <SidebarInner
              className="sidebar__nav"
              user={userElement}
              headerLinks={<MobileNav />}
              links={links}
            />
            {bgSidebar ? (
              <div
                className="sidebar__background"
                style={{ backgroundImage: 'url(' + bgSidebar + ')' }}
              />
            ) : null}
          </CustomDrawerStyled>
        </Hidden>
        <Hidden mdDown>
          <CustomDrawerStyled
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: 'drawer__paper drawer__paper--inner',
            }}
            className={classNames({
              sidebar__inner: true,
              'sidebar__inner-minisize': miniSidebar,
            })}
          >
            {brandElement}
            <SidebarInner
              className="sidebar__nav"
              user={userElement}
              links={links}
            />
            {bgSidebar ? (
              <div
                className="sidebar__background"
                style={{ backgroundImage: 'url(' + bgSidebar + ')' }}
              />
            ) : null}
          </CustomDrawerStyled>
        </Hidden>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
