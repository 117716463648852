import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import type { RootState } from 'store/configureStore';
// import { AppThunk } from 'store/configureStore';
// import { setOther } from 'store/slices/otherSlice';

// Define a type for the slice state
interface OtherState {
  colorMode: 'light' | 'dark';
  isRefreshingToken: boolean;
  isMiniSidebar: boolean;
  isNavMobileOpen: boolean;
}

// Define the initial state using that type
const initialState: OtherState = {
  colorMode: 'light',
  isRefreshingToken: false,
  isMiniSidebar: false,
  isNavMobileOpen: false,
};

export const otherSlice = createSlice({
  name: 'other',
  initialState,
  reducers: {
    setColorMode: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.colorMode = action.payload;
      return state;
    },
    setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingToken = action.payload;
      return state;
    },
    setIsMiniSidebar: (state, action: PayloadAction<boolean>) => {
      state.isMiniSidebar = action.payload;
      return state;
    },
    setIsNavMobileOpen: (state, action: PayloadAction<boolean>) => {
      state.isNavMobileOpen = action.payload;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setColorMode,
  setIsRefreshingToken,
  setIsMiniSidebar,
  setIsNavMobileOpen,
} = otherSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const getOtherRoot = (state: RootState) => state.other.type;
// export const setOtherRoot =
//   (type: string): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = getOtherRoot(getState());
//     if (currentValue !== type) {
//       dispatch(setOther(type));
//     }
//   };

export default otherSlice;
