import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';

export const CustomInputStyled = styled(FormControl)(({ theme }) => ({
  position: 'relative',
  margin: '15px 0',
  '.fab, .far, .fal, .fas, svg, .material-icons': {
    color: theme.custom.grayIcon,
  },
  '.label': {
    cursor: 'pointer',
    color: theme.custom.grayIcon,
    fontSize: '14px',
    '&__root': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '1.4',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      // fix scale transform
      maxWidth: '133%',
      '&-error': {
        color: theme.custom.danger + ' !important',
      },
      '&-success': {
        color: theme.custom.success + ' !important',
      },
    },
    '&__feedback': {
      position: 'absolute',
      top: '2px',
      right: 0,
      display: 'block',
      width: '24px',
      height: '24px',
      pointerEvents: 'none',
    },
  },
  '.input': {
    color: theme.custom.grayIcon,
    '&, &::placeholder': {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '1.4',
      opacity: 1,
    },
    '&::placeholder': {
      color: theme.custom.grayIcon,
    },
    '&--underline': {
      '&:hover:not(.disabled)::before, &::before': {
        borderBottomColor: theme.palette.grey[400] + ' !important',
        borderBottomWidth: '1px !important',
      },
      '&::after': {
        borderBottomColor: theme.custom.primary,
      },
      '&-error': {
        '&::after': {
          borderBottomColor: theme.custom.danger,
        },
      },
      '&-success': {
        '&::after': {
          borderBottomColor: theme.custom.success,
        },
      },
    },
  },
  '.txt__message': {
    color: theme.custom.danger,
    fontSize: '10px',
    marginTop: '5px',
    marginBottom: '-19px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
