import React from 'react';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppSelector, useAppDispatch } from 'hooks';

// stores
import { setIsMiniSidebar, setIsNavMobileOpen } from 'store/slices/otherSlice';

// components
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import ViewList from '@mui/icons-material/ViewList';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/icons-material/Menu';
import Navbar from 'components/Navbar';
import CustomButton from 'components/CustomButton';

// styles
import { HeadStyled } from 'assets/styled/cstyled/headStyled';

const Head = ({ brandText, ...rest }: { brandText: string }): JSX.Element => {
  const miniSidebar = useAppSelector(
    (state) => state.other.isMiniSidebar,
    shallowEqual
  );
  const navMobile = useAppSelector(
    (state) => state.other.isNavMobileOpen,
    shallowEqual
  );
  const dispatch = useAppDispatch();

  const toggleMiniSidebar = () => {
    dispatch(setIsMiniSidebar(!miniSidebar));
  };

  const handleDrawerMobileToggle = () => {
    dispatch(setIsNavMobileOpen(!navMobile));
  };

  return (
    <HeadStyled {...rest}>
      <Toolbar className="toolbar">
        <Hidden mdDown>
          <div>
            {miniSidebar ? (
              <CustomButton
                justIcon
                round
                color="white"
                onClick={toggleMiniSidebar}
              >
                <ViewList />
              </CustomButton>
            ) : (
              <CustomButton
                justIcon
                round
                color="white"
                onClick={toggleMiniSidebar}
              >
                <MoreVert />
              </CustomButton>
            )}
          </div>
        </Hidden>
        <div className="item--flex">
          <div className="title">
            <span>{brandText}</span>
          </div>
        </div>
        <Hidden mdDown>
          <Navbar />
        </Hidden>
        <Hidden mdUp>
          <CustomButton
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerMobileToggle}
          >
            <Menu />
          </CustomButton>
        </Hidden>
      </Toolbar>
    </HeadStyled>
  );
};

export default React.memo(Head);
