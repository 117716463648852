import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// styles
import { FooterStyled } from 'assets/styled/cstyled/footerStyled';

// others
import { NAV_FOOTER } from 'constants/nav';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  const navi = (
    <List className="footer__list">
      {NAV_FOOTER.map((nav, index) => {
        return (
          <ListItem key={index} className="footer__nav--item">
            <Link to={nav.path || '#'} className="footer__nav--link">
              {t(nav.text)}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <FooterStyled className="footer">
      <div className="container clearfix">
        <div className="footer__nav">{navi}</div>
        <p className="copyright">
          <span>&copy; 2021 {t('BRAND')}</span>
        </p>
      </div>
    </FooterStyled>
  );
};

export default React.memo(Footer);
