import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// libraries
import PerfectScrollbar from 'perfect-scrollbar';

// components
import SwitchSlider from 'components/SwitchSlider';

// styles
import { ErrorStyled } from 'assets/styled/pstyled/errorStyled';

let ps: any;

const NotFound = (): JSX.Element => {
  const [t] = useTranslation();

  const mainContent = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkPerfectScrollbar = () => {
      if (
        // @ts-ignore
        navigator.userAgentData?.platform.indexOf('Windows') !== -1 &&
        mainContent?.current
      ) {
        // @ts-ignore
        ps = new PerfectScrollbar(mainContent.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = 'hidden';
      } else if (ps) {
        document.body.style.overflow = 'auto';
        ps.destroy();
      }
    };
    checkPerfectScrollbar();
    window.addEventListener('resize', checkPerfectScrollbar);
    return () => {
      // @ts-ignore
      if (navigator.userAgentData?.platform.indexOf('Windows') !== -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener('resize', checkPerfectScrollbar);
    };
  }, []);

  return (
    <div ref={mainContent} className="layout__element">
      <div className="nbar" style={{ position: 'fixed' }}>
        <SwitchSlider />
      </div>
      <div className="item--over">
        <ErrorStyled className="page page--simple">
          <div className="page__content--error">
            <div className="error error__code">404</div>
            <div className="error error__message">{t('PAGE_NOT_FOUND')}</div>
          </div>
        </ErrorStyled>
      </div>
    </div>
  );
};

export default React.memo(NotFound);
