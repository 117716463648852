import { ThemeOptions } from '@mui/material';
import { CustomTOption } from 'types/customTOptionType';

interface CustomThemeOptions extends ThemeOptions {
  custom?: CustomTOption;
}

export const dark: CustomThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      light: '#e3f2fd',
      dark: '#42a5f5',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: '#ce93d8',
      light: '#f3e5f5',
      dark: '#ab47bc',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      main: '#ffa726',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#29b6f6',
      light: '#4fc3f7',
      dark: '#0288d1',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: {
      paper: '#121212',
      default: '#121212',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },
  custom: {
    default: '#4c4c4c',
    background: '#121212',
    primary: '#4d1358',
    info: '#005660',
    success: '#255728',
    warning: '#7f4c00',
    danger: '#8d1007',
    rose: '#770b30',
    grayIcon: '#ddd',
    draw: 'rgba(50, 50, 50, 0.8)',
    text: {
      title: '#fff',
    },
    linear: {
      primaryFirst: '#56225e',
      primarySecond: '#461254',
      infoFirst: '#12636d',
      infoSecond: '#005660',
      successFirst: '#2c642f',
      successSecond: '#215023',
      warningFirst: '#925700',
      warningSecond: '#7d4600',
      dangerFirst: '#920f0d',
      dangerSecond: '#7c1210',
      roseFirst: '#880d36',
      roseSecond: '#6c0d30',
    },
    social: {
      apple: '#000',
      facebook: '#043e75',
      google: '#6f2016',
    },
    hover: {
      nav: '#646464',
    },
  },
};
