import React from 'react';

// libraries
import classNames from 'classnames';

// styles
import { CustomButtonStyled } from 'assets/styled/cstyled/customButtonStyled';

type ButtonProps = JSX.IntrinsicElements['button'];

/* eslint-disable react/prop-types */
const CustomButton = React.forwardRef<
  HTMLButtonElement | any,
  ButtonProps | any
>((props, ref) => {
  const {
    color,
    size,
    simple,
    round,
    fullWidth,
    disabled,
    justIcon,
    children,
    className,
    ...rest
  } = props;
  const btnClasses = classNames({
    btn: true,
    ['btn--' + color]: color,
    ['btn--' + size]: size,
    'btn--simple': simple,
    'btn--round': round,
    'btn--full-width': fullWidth,
    'btn--disabled': disabled,
    'btn--just-icon': justIcon,
    [className]: className,
  });

  return (
    <CustomButtonStyled {...rest} ref={ref} className={btnClasses}>
      {children}
    </CustomButtonStyled>
  );
});

export default React.memo(CustomButton);
