import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from 'types/authType';

// Define a type for the slice state

// Define the initial state using that type
const initialState: Auth = {
  loggedIn: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth } = authSlice.actions;

export default authSlice;
