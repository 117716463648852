import { toast, ToastOptions } from 'react-toastify';

export const customToast = (
  type = 'default',
  message: string | { pending: string; success: string; error: string },
  func?: Promise<any>, // it is required when type is promise
  option?: ToastOptions
) => {
  const defaultValue: ToastOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const toastOption = { ...defaultValue, ...option };

  switch (type) {
    case 'success':
      return toast.success(message, toastOption);
    case 'info':
      return toast.info(message, toastOption);
    case 'warning':
      return toast.warning(message, toastOption);
    case 'error':
      return toast.error(message, toastOption);
    case 'promise':
      // @ts-ignore
      return toast.promise(func, message);
    default:
      return toast(message, toastOption);
  }
};
