import { styled } from '@mui/material/styles';

export const LoginStyled = styled('div')(({ theme }) => ({
  '&.page__login': {
    position: 'relative',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  '.container': {
    zIndex: '5',
  },
  '.card__login': {
    marginTop: '40px',
  },
  '.card__header': {
    padding: '15px',
    margin: '-40px 20px 15px 20px',
    textAlign: 'center',
  },
  '.card__footer': {
    paddingBottom: '30px',
  },
  '.card__title': {
    fontFamily: 'Roboto Slab, Times New Roman, serif',
    color: 'inherit',
    fontWeight: 700,
    marginTop: '10px',
    marginBottom: '12px',
  },
  '.list__social': {
    marginTop: '1rem',
    textAlign: 'center',
  },
  '.txt__description': {
    color: theme.custom.default,
  },
}));
