import { styled } from '@mui/material/styles';

export const ErrorStyled = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 500,
  backgroundColor: theme.palette.background.default,
  '.page__content--error': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.error__code': {
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    margin: 0,
    marginRight: '20px',
    padding: '10px 23px 10px 0',
    fontSize: '24px',
    fontWeight: '500',
  },
  '.error__message': {
    fontSize: '14px',
    textAlign: 'left',
    maxWidth: '75vw',
    display: 'flex',
    alignItems: 'center',
  },
}));
