import Dashboard from '@mui/icons-material/Dashboard';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Fingerprint from '@mui/icons-material/Fingerprint';
import { APP_ROUTES } from 'constants/app-routes';
import { NavType } from 'types/navType';

export const NAV_HEADER: NavType[] = [
  {
    path: APP_ROUTES.HOME,
    icon: Dashboard,
    text: 'DASHBOARD',
  },
  {
    path: APP_ROUTES.REGISTER,
    icon: PersonAdd,
    text: 'REGISTER',
  },
  {
    path: APP_ROUTES.LOGIN,
    icon: Fingerprint,
    text: 'LOGIN',
  },
];

export const NAV_FOOTER: NavType[] = [
  {
    path: APP_ROUTES.HOME,
    text: 'HOME',
  },
];

export const NAV_SIDEBAR: NavType[] = [
  {
    path: APP_ROUTES.HOME,
    icon: Dashboard,
    text: 'DASHBOARD',
  },
];
