import React from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppSelector, useAppDispatch } from 'hooks';

// stores
import { setError } from 'store/slices/errorSlice';

// styles
import { ErrorStyled } from 'assets/styled/pstyled/errorStyled';

const ErrorLayout = (): JSX.Element => {
  const error = useAppSelector((state) => state.error, shallowEqual);
  const history = useHistory();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goBack = () => {
    dispatch(setError(null));
    history.goBack();
  };

  return (
    <ErrorStyled className="page page--simple">
      <div className="page__content--error">
        <div className="error error__code">{error.statusCode}</div>
        <div
          className="error error__message"
          dangerouslySetInnerHTML={{ __html: error.message || '' }}
        ></div>
      </div>
    </ErrorStyled>
  );
};

export default React.memo(ErrorLayout);
