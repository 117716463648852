import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/icons-material/Menu';
import CustomButton from 'components/CustomButton';

// styles
import { HeaderStyled } from 'assets/styled/cstyled/headerStyled';
import { CustomDrawerStyled } from 'assets/styled/cstyled/customDrawerStyled';

// others
import { APP_ROUTES } from 'constants/app-routes';
import { NAV_HEADER } from 'constants/nav';

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  const navi = (
    <List className="header__list clearfix">
      {NAV_HEADER.map((nav, index) => {
        if (nav.path === location.pathname) {
          return null;
        }
        return (
          <ListItem key={index} className="header__list-item">
            <Link to={nav.path || '#'} className="nav__link">
              {/* @ts-ignore */}
              <nav.icon className="header__list-item-icon" />
              <ListItemText
                primary={t(nav.text)}
                disableTypography={true}
                className="header__list-item-text"
              />
            </Link>
          </ListItem>
        );
      })}
    </List>
  );

  const handleDrawerToggle = () => {
    setIsNavOpen((prevOpen: boolean) => !prevOpen);
  };

  return (
    <HeaderStyled position="static" className="header">
      <Toolbar className="container">
        <div className="item--flex">
          <Link to={APP_ROUTES.HOME} className="title">
            {t('BRAND')}
          </Link>
        </div>
        <Hidden mdDown>{navi}</Hidden>
        <Hidden mdUp>
          <CustomButton
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </CustomButton>
        </Hidden>
        <Hidden mdUp>
          <CustomDrawerStyled
            variant="temporary"
            anchor="right"
            open={isNavOpen}
            classes={{
              paper: 'drawer__paper',
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {navi}
          </CustomDrawerStyled>
        </Hidden>
      </Toolbar>
    </HeaderStyled>
  );
};

export default React.memo(Header);
