import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { hexToRgb } from 'assets/styled/variableStyle';

export const CustomButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.custom.default,
  color: theme.palette.common.white,
  boxShadow:
    '0 2px 2px 0 rgba(' +
    hexToRgb(theme.custom.default) +
    ', 0.14), 0 3px 1px -2px rgba(' +
    hexToRgb(theme.custom.default) +
    ', 0.2), 0 1px 5px 0 rgba(' +
    hexToRgb(theme.custom.default) +
    ', 0.12)',
  border: 'none',
  borderRadius: '3px',
  position: 'relative',
  padding: '12px 30px',
  margin: '5px 1px',
  fontSize: '12px',
  fontWeight: 400,
  textTransform: 'uppercase',
  willChange: 'box-shadow, transform',
  transition:
    'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  lineHeight: '1.4',
  textAlign: 'center',
  touchAction: 'manipulation',
  cursor: 'pointer',
  '&:hover, &:focus': {
    backgroundColor: theme.custom.default,
    color: theme.palette.common.white,
    boxShadow:
      '0 14px 26px -12px rgba(' +
      hexToRgb(theme.custom.default) +
      ', 0.42), 0 4px 23px 0 rgba(' +
      hexToRgb(theme.palette.common.black) +
      ', 0.12), 0 8px 10px -5px rgba(' +
      hexToRgb(theme.custom.default) +
      ', 0.2)',
  },
  '&.btn': {
    '&--icon-button': {
      marginRight: '3px',
      marginLeft: '3px',
    },
    '&--inherit': {
      color: 'inherit',
    },
    '&--primary': {
      backgroundColor: theme.custom.primary,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.primary) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.primary) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.primary) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.primary,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.primary) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.primary) +
          ', 0.2)',
      },
    },
    '&--info': {
      backgroundColor: theme.custom.info,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.info) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.info) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.info) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.info,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.info) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.info) +
          ', 0.2)',
      },
    },
    '&--success': {
      backgroundColor: theme.custom.success,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.success) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.success) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.success) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.success,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.success) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.success) +
          ', 0.2)',
      },
    },
    '&--warning': {
      backgroundColor: theme.custom.warning,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.warning) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.warning) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.warning) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.warning,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.warning) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.warning) +
          ', 0.2)',
      },
    },
    '&--danger': {
      backgroundColor: theme.custom.danger,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.danger) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.danger) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.danger) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.danger,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.danger) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.danger) +
          ', 0.2)',
      },
    },
    '&--rose': {
      backgroundColor: theme.custom.rose,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.rose) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.rose) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.rose) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.rose,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.rose) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.rose) +
          ', 0.2)',
      },
    },
    '&--white': {
      '&, &:focus, &:hover': {
        backgroundColor:
          theme.palette.mode === 'light'
            ? theme.palette.common.white
            : theme.palette.grey[700],
        color:
          theme.palette.mode === 'light' ? theme.custom.default : 'inherit',
      },
    },
    '&--transparent': {
      '&, &:focus, &:hover': {
        color: 'inherit',
        background: 'transparent',
        boxShadow: 'none',
      },
    },
    '&--apple': {
      backgroundColor: theme.custom.social.apple,
      color: theme.palette.common.white,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.social.apple) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.social.apple) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.social.apple) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.social.apple,
        color: theme.palette.common.white,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.social.apple) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.social.apple) +
          ', 0.2)',
      },
    },
    '&--facebook': {
      backgroundColor: theme.custom.social.facebook,
      color: theme.palette.common.white,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.social.facebook) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.social.facebook) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.social.facebook) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.social.facebook,
        color: theme.palette.common.white,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.social.facebook) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.social.facebook) +
          ', 0.2)',
      },
    },
    '&--google': {
      backgroundColor: theme.custom.social.google,
      color: theme.palette.common.white,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.custom.social.google) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.custom.social.google) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.custom.social.google) +
        ', 0.12)',
      '&:hover, &:focus': {
        backgroundColor: theme.custom.social.google,
        color: theme.palette.common.white,
        boxShadow:
          '0 14px 26px -12px rgba(' +
          hexToRgb(theme.custom.social.google) +
          ', 0.42), 0 4px 23px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 8px 10px -5px rgba(' +
          hexToRgb(theme.custom.social.google) +
          ', 0.2)',
      },
    },
    '&--disabled': {
      opacity: '0.65',
      pointerEvents: 'none',
    },
    '&--lg': {
      padding: '1.125rem 2.25rem',
      fontSize: '0.875rem',
      lineHeight: '1.333333',
      borderRadius: '0.2rem',
      '&.btn--just-icon': {
        '.fab, .fas, .far, .fal, svg, .material-icons': {
          marginTop: '-4px',
        },
      },
    },
    '&--sm': {
      padding: '0.40625rem 1.25rem',
      fontSize: '0.6875rem',
      lineHeight: '1.5',
      borderRadius: '0.2rem',
      '&.btn--just-icon': {
        '.fab, .fas, .far, .fal, svg, .material-icons': {
          marginTop: '1px',
        },
      },
    },
    '&--just-icon': {
      width: '41px',
      height: '41px',
      minWidth: 'auto',
      fontSize: '20px',
      paddingLeft: '12px',
      paddingRight: '12px',
      '&.btn--lg': {
        width: '57px',
        height: '57px',
        lineHeight: '56px',
        '.fab, .fas, .far, .fal, .material-icons': {
          fontSize: '32px',
          lineHeight: '56px',
        },
        svg: {
          width: '32px',
          height: '32px',
        },
      },
      '&.btn--sm': {
        width: '30px',
        height: '30px',
        '.fab, .fas, .far, .fal, .material-icons': {
          fontSize: '17px',
          lineHeight: '29px',
        },
        svg: {
          width: '17px',
          height: '17px',
        },
      },
    },
    '&--full-width': {
      width: '100%',
    },
    '&--simple': {
      '&, &:hover, &:focus': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&.btn--primary': {
        '&, &:hover, &:focus': {
          color: theme.custom.primary,
        },
      },
      '&.btn--info': {
        '&, &:hover, &:focus': {
          color: theme.custom.info,
        },
      },
      '&.btn--success': {
        '&, &:hover, &:focus': {
          color: theme.custom.success,
        },
      },
      '&.btn--warning': {
        '&, &:hover, &:focus': {
          color: theme.custom.warning,
        },
      },
      '&.btn--danger': {
        '&, &:hover, &:focus': {
          color: theme.custom.danger,
        },
      },
      '&.btn--rose': {
        '&, &:hover, &:focus': {
          color: theme.custom.rose,
        },
      },
      '&.btn--apple': {
        '&:hover, &:focus': {
          color: theme.custom.social.apple,
        },
      },
      '&.btn--facebook': {
        '&, &:hover, &:focus': {
          color: theme.custom.social.facebook,
        },
      },
      '&.btn--google': {
        '&, &:hover, &:focus': {
          color: theme.custom.social.google,
        },
      },
    },
    '&--round': {
      borderRadius: '30px',
    },
    '&--action': {
      padding: '5px',
      minWidth: 'auto',
    },
  },
  '.fab, .fas, .far, .fal, .material-icons': {
    fontSize: '1.1rem',
  },
  svg: {
    width: '18px',
    height: '18px',
  },
}));
