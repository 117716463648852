import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// layouts
import AuthLayout from 'layouts/AuthLayout';

// components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Storage from '@mui/icons-material/Storage';
import Warning from '@mui/icons-material/Warning';
import Store from '@mui/icons-material/Store';
import DateRange from '@mui/icons-material/DateRange';
import LocalOffer from '@mui/icons-material/LocalOffer';
import People from '@mui/icons-material/People';
import Update from '@mui/icons-material/Update';
import Edit from '@mui/icons-material/Edit';
import Refresh from '@mui/icons-material/Refresh';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import AccessTime from '@mui/icons-material/AccessTime';
import Place from '@mui/icons-material/Place';
import ChartistGraph from 'react-chartist';
import Panel from 'components/Panel';
import CustomButton from 'components/CustomButton';

// styles
import { HomeStyled } from 'assets/styled/pstyled/homeStyled';

// others
import packageInfo from '../../package.json';
import { dailyChart, monthChart, workTimeChart } from 'constants/charts';
import placeFirst from 'assets/images/place/place-first.jpeg';
import placeSecond from 'assets/images/place/place-second.jpeg';
import placeThird from 'assets/images/place/place-third.jpeg';

const Home = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      {/* @ts-ignore */}
      <Panel brandText={t('DASHBOARD')}>
        <div className="page page--other">
          <HomeStyled className="wrap__inner">
            <Grid container justifyContent="center" className="grid">
              <Grid item xs={12} sm={6} md={6} lg={3} className="grid__item">
                <div className="card card__normal card__statistic">
                  <div className="card__header clearfix">
                    <div className="card__icon card__icon--warning">
                      <Storage />
                    </div>
                    <p className="card__category line__dot">
                      {t('STATISTIC_STORAGE_CATEGORY')}
                    </p>
                    <div title="48/50 GB" className="card__title">
                      <span className="line__dot">48/50</span> <small>GB</small>
                    </div>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <div className="txt--danger">
                        <Warning />
                      </div>
                      <Link to={'#'} className="line__dot">
                        {t('STATISTIC_STORAGE_GET_MORE')}
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} className="grid__item">
                <div className="card card__normal card__statistic">
                  <div className="card__header clearfix">
                    <div className="card__icon card__icon--success">
                      <Store />
                    </div>
                    <p className="card__category line__dot">
                      {t('STATISTIC_REVENUE_CATEGORY')}
                    </p>
                    <div title="$24,034" className="card__title">
                      <span className="line__dot">$24,034</span>
                    </div>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <DateRange />
                      <span className="line__dot">
                        {t('STATISTIC_REVENUE_LAST_TIME')}
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} className="grid__item">
                <div className="card card__normal card__statistic">
                  <div className="card__header clearfix">
                    <div className="card__icon card__icon--info">
                      <People />
                    </div>
                    <p className="card__category line__dot">{t('FOLLOWER')}</p>
                    <div title="2403" className="card__title">
                      <span className="line__dot">2403</span>
                    </div>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <Update />
                      <span className="line__dot">{t('UPDATED_TIME')}</span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} className="grid__item">
                <div className="card card__normal card__statistic">
                  <div className="card__header clearfix">
                    <div className="card__icon card__icon--danger">
                      <i className="fab fa-git-alt"></i>
                    </div>
                    <p className="card__category line__dot">{t('VERSION')}</p>
                    <div title={packageInfo.version} className="card__title">
                      <span className="line__dot">{packageInfo.version}</span>
                    </div>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <LocalOffer />
                      <span className="line__dot">{t('TRACK')}</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container className="grid">
              <Grid item sm={12} md={4} className="grid__item">
                <div className="card card__normal card__has-hover card__chart">
                  <div className="card__header card__header--info clearfix">
                    <ChartistGraph
                      type="Line"
                      data={dailyChart.data}
                      // @ts-ignore
                      options={dailyChart.options}
                      listener={dailyChart.animation}
                      className="ct-chart-white-colors"
                    />
                  </div>
                  <div className="card__body">
                    <div className="card__hover txt--center">
                      <Tooltip
                        // @ts-ignore
                        title={t('REFRESH')}
                        placement="bottom"
                        classes={{ tooltip: 'tooltip' }}
                      >
                        <CustomButton
                          simple
                          color="info"
                          className="btn--just-icon"
                        >
                          <Refresh />
                        </CustomButton>
                      </Tooltip>
                      <Tooltip
                        // @ts-ignore
                        title={t('EDIT')}
                        placement="bottom"
                        classes={{ tooltip: 'tooltip' }}
                      >
                        <CustomButton
                          color="transparent"
                          simple
                          className="btn--just-icon"
                        >
                          <Edit />
                        </CustomButton>
                      </Tooltip>
                    </div>
                    <h4 className="card__title card__title--no-space">
                      {t('DAILY_SALES')}
                    </h4>
                    <p className="card__category card__category--content">
                      <span className="txt--success">
                        <ArrowUpward className="icon__up" /> 55%
                      </span>{' '}
                      {t('INCREASE_TODAY')}
                    </p>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <AccessTime />
                      <span className="line__dot">{t('UPDATED_TIME')}</span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={4} className="grid__item">
                <div className="card card__normal card__has-hover card__chart">
                  <div className="card__header card__header--warning clearfix">
                    <ChartistGraph
                      type="Bar"
                      data={monthChart.data}
                      // @ts-ignore
                      options={monthChart.options}
                      responsiveOptions={monthChart.responsiveOptions}
                      listener={monthChart.animation}
                      className="ct-chart-white-colors"
                    />
                  </div>
                  <div className="card__body">
                    <div className="card__hover txt--center">
                      <Tooltip
                        // @ts-ignore
                        title={t('REFRESH')}
                        placement="bottom"
                        classes={{ tooltip: 'tooltip' }}
                      >
                        <CustomButton
                          simple
                          color="info"
                          className="btn--just-icon"
                        >
                          <Refresh />
                        </CustomButton>
                      </Tooltip>
                      <Tooltip
                        // @ts-ignore
                        title={t('EDIT')}
                        placement="bottom"
                        classes={{ tooltip: 'tooltip' }}
                      >
                        <CustomButton
                          color="transparent"
                          simple
                          className="btn--just-icon"
                        >
                          <Edit />
                        </CustomButton>
                      </Tooltip>
                    </div>
                    <h4 className="card__title card__title--no-space">
                      {t('EMAIL_SUBSCRIPTIONS')}
                    </h4>
                    <p className="card__category card__category--content">
                      {t('LAST_CAMPAIGN')}
                    </p>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <AccessTime />
                      <span className="line__dot">{t('SENT_AGO')}</span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={4} className="grid__item">
                <div className="card card__normal card__has-hover card__chart">
                  <div className="card__header card__header--danger clearfix">
                    <ChartistGraph
                      type="Line"
                      data={workTimeChart.data}
                      // @ts-ignore
                      options={workTimeChart.options}
                      listener={workTimeChart.animation}
                      className="ct-chart-white-colors"
                    />
                  </div>
                  <div className="card__body">
                    <div className="card__hover txt--center">
                      <Tooltip
                        // @ts-ignore
                        title={t('REFRESH')}
                        placement="bottom"
                        classes={{ tooltip: 'tooltip' }}
                      >
                        <CustomButton
                          simple
                          color="info"
                          className="btn--just-icon"
                        >
                          <Refresh />
                        </CustomButton>
                      </Tooltip>
                      <Tooltip
                        // @ts-ignore
                        title={t('EDIT')}
                        placement="bottom"
                        classes={{ tooltip: 'tooltip' }}
                      >
                        <CustomButton
                          color="transparent"
                          simple
                          className="btn--just-icon"
                        >
                          <Edit />
                        </CustomButton>
                      </Tooltip>
                    </div>
                    <h4 className="card__title card__title--no-space">
                      {t('COMPLETED_TASKS')}
                    </h4>
                    <p className="card__category card__category--content">
                      {t('LAST_CAMPAIGN')}
                    </p>
                  </div>
                  <div className="card__footer">
                    <div className="card__stats">
                      <AccessTime />
                      <span className="line__dot">{t('UPDATED_TIME')}</span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>

            <h3>{t('MANAGE_LIST')}</h3>
            <Grid container className="grid">
              <Grid item sm={12} md={4} className="grid__item">
                <div className="card card__normal card__popular txt--center">
                  <div className="card__header clearfix">
                    <Link to={'#'}>
                      <img src={placeFirst} alt={t('PLACE_TITLE_FIRST')} />
                    </Link>
                  </div>
                  <div className="card__body">
                    <h4 className="card__title card__title--no-space">
                      {t('PLACE_TITLE_FIRST')}
                    </h4>
                    <p className="card__category card__category--content">
                      {t('PLACE_CONTENT_FIRST')}
                    </p>
                  </div>
                  <div className="card__footer card__footer--space">
                    <div className="card__footer--left">$899/{t('NIGHT')}</div>
                    <div className="card__footer--right">
                      <Place /> Barcelona, Spain
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={4} className="grid__item">
                <div className="card card__normal card__popular txt--center">
                  <div className="card__header clearfix">
                    <Link to={'#'}>
                      <img src={placeSecond} alt={t('PLACE_TITLE_SECOND')} />
                    </Link>
                  </div>
                  <div className="card__body">
                    <h4 className="card__title card__title--no-space">
                      {t('PLACE_TITLE_SECOND')}
                    </h4>
                    <p className="card__category card__category--content">
                      {t('PLACE_CONTENT_SECOND')}
                    </p>
                  </div>
                  <div className="card__footer card__footer--space">
                    <div className="card__footer--left">
                      $1.119/{t('NIGHT')}
                    </div>
                    <div className="card__footer--right">
                      <Place /> London, UK
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={4} className="grid__item">
                <div className="card card__normal card__popular txt--center">
                  <div className="card__header clearfix">
                    <Link to={'#'}>
                      <img src={placeThird} alt={t('PLACE_TITLE_THIRD')} />
                    </Link>
                  </div>
                  <div className="card__body">
                    <h4 className="card__title card__title--no-space">
                      {t('PLACE_TITLE_THIRD')}
                    </h4>
                    <p className="card__category card__category--content">
                      {t('PLACE_CONTENT_THIRD')}
                    </p>
                  </div>
                  <div className="card__footer card__footer--space">
                    <div className="card__footer--left">$459/{t('NIGHT')}</div>
                    <div className="card__footer--right">
                      <Place /> Milan, Italy
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </HomeStyled>
        </div>
      </Panel>
    </AuthLayout>
  );
};

export default React.memo(Home);
