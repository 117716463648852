import { styled } from '@mui/material/styles';

export const LoadingStyled = styled('label')(({ theme }) => ({
  '.ispinner__blade': {
    backgroundColor: theme.palette.text.primary,
  },
  '.bubble__pulse': {
    backgroundColor: theme.palette.text.primary,
  },
}));
