import jwt from 'jsonwebtoken';

/**
 * Check JWT token
 *
 * @param  {string} token
 * @returns {boolean} true if the specified token is alive token, false otherwise.
 */
export const isAliveJWT = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const tokenDecode = decodeJWT(token);
  if (tokenDecode && tokenDecode.exp * 1000 > new Date().getTime()) {
    return true;
  }
  return false;
};

/**
 * Decode JWT token
 *
 * @param  {string} token
 * @returns {any} JWT token or false
 */
export const decodeJWT = (token: string): any => {
  if (!token) {
    return false;
  }

  const decode = jwt.decode(token);
  return decode;
};
