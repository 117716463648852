import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { hexToRgb } from 'assets/styled/variableStyle';

export const HeaderStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  color: theme.palette.common.white,
  boxShadow: 'none',
  padding: '10px 0',
  zIndex: '10',
  a: {
    color: theme.palette.common.white,
  },
  '&': {
    [theme.breakpoints.down('lg')]: {
      '.container': {
        maxWidth: 'none',
      },
      '.title': {
        marginLeft: '12px',
      },
    },
  },
  '.header': {
    '&__list': {
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('lg')]: {
        paddingRight: '60px',
      },
      '&-item': {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          width: 'auto',
          float: 'left',
        },
        '&-icon': {
          width: '20px',
          height: '20px',
          display: 'inline-block',
          verticalAlign: 'middle',
          marginTop: '-3px',
          marginRight: '3px',
        },
        '&-text': {
          flex: 'none',
          margin: 0,
          display: 'inline-block',
          verticalAlign: 'middle',
        },
      },
    },
  },
  '.title': {
    fontSize: '18px',
    textTransform: 'uppercase',
  },
  '.nav__link': {
    color: theme.palette.common.white,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: 1.5,
    display: 'block',
    padding: '15px',
    margin: '0 5px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    '&:hover, &:focus': {
      background: 'rgba(' + hexToRgb(theme.custom.hover.nav) + ', 0.2)',
    },
  },
}));
