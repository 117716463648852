import React, { useEffect } from 'react';

// libraries
import PerfectScrollbar from 'perfect-scrollbar';

// components
import SwitchSlider from 'components/SwitchSlider';
import Header from 'components/Header';
import Footer from 'components/Footer';

// styles
import { LayoutMaskStyled } from 'assets/styled/cstyled/layoutMaskStyled';

let ps: any;

const DefaultLayout = ({
  children,
  backgroundImage,
}: {
  children: JSX.Element;
  backgroundImage?: string;
}): JSX.Element => {
  const mainContent = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkPerfectScrollbar = () => {
      if (
        // @ts-ignore
        navigator.userAgentData?.platform.indexOf('Windows') !== -1 &&
        mainContent?.current
      ) {
        // @ts-ignore
        ps = new PerfectScrollbar(mainContent.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = 'hidden';
      } else if (ps) {
        document.body.style.overflow = 'auto';
        ps.destroy();
      }
    };
    checkPerfectScrollbar();
    window.addEventListener('resize', checkPerfectScrollbar);
    return () => {
      // @ts-ignore
      if (navigator.userAgentData?.platform.indexOf('Windows') !== -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener('resize', checkPerfectScrollbar);
    };
  }, []);

  return (
    <div ref={mainContent} className="layout__element">
      <div className="nbar">
        <SwitchSlider />
      </div>
      {backgroundImage ? (
        <LayoutMaskStyled
          className="layout__content"
          style={{
            backgroundImage: 'url(' + backgroundImage + ')',
          }}
        >
          <div className="item--over">
            <Header />
            {children}
            <Footer />
          </div>
        </LayoutMaskStyled>
      ) : (
        <div className="layout__content">
          <div className="item--over">
            <Header />
            {children}
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DefaultLayout);
