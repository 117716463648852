import React from 'react';

// libraries
import classNames from 'classnames';

// components
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Clear from '@mui/icons-material/Clear';
import Check from '@mui/icons-material/Check';

// styles
import { CustomInputStyled } from 'assets/styled/cstyled/customInputStyled';

const CustomInput = ({
  formControlProps,
  labelText,
  id,
  success,
  error,
  inputProps,
  helpText,
}: {
  formControlProps?: any;
  labelText?: string;
  id?: string;
  success?: boolean;
  error?: boolean;
  inputProps: any;
  helpText?: string;
}): JSX.Element => {
  let formControlClasses;
  if (formControlProps?.className) {
    formControlClasses = classNames(
      formControlProps.className,
      'form__control'
    );
  } else {
    formControlClasses = 'form__control';
  }
  const labelClasses = classNames({
    label: true,
    label__root: true,
    'label__root-error': error,
    'label__root-success': success && !error,
  });
  const inputClasses = classNames({
    input: true,
  });
  const underlineClasses = classNames({
    'input--underline': true,
    'input--underlineError': error,
    'input--underlineSuccess': success && !error,
  });

  return (
    <CustomInputStyled {...formControlProps} className={formControlClasses}>
      {labelText ? (
        <InputLabel className={labelClasses} htmlFor={id}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        id={id}
        classes={{
          input: inputClasses,
          underline: underlineClasses,
        }}
        {...inputProps}
      />
      {error ? (
        <Clear className="label__feedback label__root-error" />
      ) : success ? (
        <Check className="label__feedback label__root-success" />
      ) : null}
      {helpText ? <div className="txt__message">{helpText}</div> : null}
    </CustomInputStyled>
  );
};

export default React.memo(CustomInput);
