import { styled } from '@mui/material/styles';
import { hexToRgb } from 'assets/styled/variableStyle';

export const BaseStyled = styled('div')(({ theme }) => ({
  a: {
    color: theme.custom.primary,
  },
  '.layout': {
    backgroundColor: theme.custom.background,
    display: 'flex',
    flexDirection: 'column',
    '&__element': {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
    },
    '&__content': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    '.item--over': {
      position: 'relative',
      zIndex: 10,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  '.container': {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    // xs: 0px
    [theme.breakpoints.up('xs')]: {
      maxWidth: '300px',
    },
    // sm: 600px
    [theme.breakpoints.up('sm')]: {
      maxWidth: '540px',
    },
    // md: 900px
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
    // lg: 1200px
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1140px',
    },
  },
  '.inner--flex': {
    display: 'flex',
    justifyContent: 'flex-end',
    '.panel__element': {
      transitionProperty: 'top, bottom, width',
      transitionDuration: '0.2s, 0.2s, 0.35s',
      transitionTimingFunction: 'linear, linear, ease',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 260px)',
      },
      [theme.breakpoints.down('md')]: {
        flex: 1,
        maxWidth: '100%',
        overflow: 'hidden',
      },
      '&.sidebar--minisize': {
        width: 'calc(100% - 80px)',
      },
      '.footer > .container': {
        maxWidth: 'none',
      },
      '.footer__nav--link, .copyright': {
        color: theme.custom.text.title,
      },
    },
  },
  '.grid': {
    marginRight: '-15px',
    marginLeft: '-15px',
    width: 'auto',
    '&__container': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  '.page--padding': {
    padding: '50px 0',
  },
  '.card': {
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.875rem',
    // some jss/css to make the cards look a bit better on Internet Explorer
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      display: 'inline-block',
    },
    '&__normal': {
      background:
        theme.palette.mode === 'light'
          ? theme.palette.common.white
          : theme.palette.grey[800],
      boxShadow:
        '0 1px 4px 0 rgba(' + hexToRgb(theme.palette.common.black) + ', 0.14)',
      marginTop: '30px',
      marginBottom: '30px',
      padding: '0 15px 10px',
      '.card__body': {
        padding: '15px 0',
      },
      '.card__title': {
        fontSize: '1.3em',
        fontWeight: 300,
        marginTop: '15px',
      },
    },
    '&__specific': {
      background: theme.palette.background.default,
      boxShadow:
        '0 2px 2px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.14), 0 3px 1px -2px rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.2), 0 1px 5px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.12)',
      '.card__body': {
        padding: '15px 30px',
      },
    },
    '&__chart': {
      '.card__header': {
        marginTop: '-20px',
        padding: '15px',
      },
      '.card__body': {
        paddingBottom: 0,
      },
    },
    '&__hover': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 'calc(-100% + 15px)',
    },
    '&__has-hover': {
      '&:hover .card__header': {
        transform: 'translate3d(0, -50px, 0)',
      },
      '.card__header': {
        position: 'relative',
        zIndex: 5,
        transition: 'all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1)',
      },
    },
    '&__popular': {
      '.card__header': {
        marginTop: '-20px',
        borderRadius: '6px',
        overflow: 'hidden',
      },
      '.card__footer': {
        '&--left': {
          fontSize: '1.3em',
          fontWeight: 300,
        },
        '&--right': {
          fontSize: '12px',
          color: theme.palette.grey[500],
          svg: {
            width: '16px',
            height: '16px',
            verticalAlign: 'middle',
            marginTop: '-2px',
          },
          '.fab, .fas, .far, .fal, .material-icons': {
            fontSize: '16px',
            verticalAlign: 'middle',
            marginTop: '-2px',
          },
        },
      },
    },
    '&__header': {
      borderRadius: '3px',
      '&--primary': {
        color: theme.palette.common.white,
        background:
          'linear-gradient(60deg, ' +
          theme.custom.linear.primaryFirst +
          ', ' +
          theme.custom.linear.primarySecond +
          ')',
        boxShadow:
          '0 12px 20px -10px rgba(' +
          hexToRgb(theme.custom.primary) +
          ', 0.28), 0 4px 20px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 7px 8px -5px rgba(' +
          hexToRgb(theme.custom.primary) +
          ', 0.2)',
      },
      '&--info': {
        color: theme.palette.common.white,
        background:
          'linear-gradient(60deg, ' +
          theme.custom.linear.infoFirst +
          ', ' +
          theme.custom.linear.infoSecond +
          ')',
        boxShadow:
          '0 12px 20px -10px rgba(' +
          hexToRgb(theme.custom.info) +
          ', 0.28), 0 4px 20px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 7px 8px -5px rgba(' +
          hexToRgb(theme.custom.info) +
          ', 0.2)',
      },
      '&--success': {
        color: theme.palette.common.white,
        background:
          'linear-gradient(60deg, ' +
          theme.custom.linear.successFirst +
          ', ' +
          theme.custom.linear.successSecond +
          ')',
        boxShadow:
          '0 12px 20px -10px rgba(' +
          hexToRgb(theme.custom.success) +
          ', 0.28), 0 4px 20px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 7px 8px -5px rgba(' +
          hexToRgb(theme.custom.success) +
          ', 0.2)',
      },
      '&--warning': {
        color: theme.palette.common.white,
        background:
          'linear-gradient(60deg, ' +
          theme.custom.linear.warningFirst +
          ', ' +
          theme.custom.linear.warningSecond +
          ')',
        boxShadow:
          '0 12px 20px -10px rgba(' +
          hexToRgb(theme.custom.warning) +
          ', 0.28), 0 4px 20px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 7px 8px -5px rgba(' +
          hexToRgb(theme.custom.warning) +
          ', 0.2)',
      },
      '&--danger': {
        color: theme.palette.common.white,
        background:
          'linear-gradient(60deg, ' +
          theme.custom.linear.dangerFirst +
          ', ' +
          theme.custom.linear.dangerSecond +
          ')',
        boxShadow:
          '0 12px 20px -10px rgba(' +
          hexToRgb(theme.custom.danger) +
          ', 0.28), 0 4px 20px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.12), 0 7px 8px -5px rgba(' +
          hexToRgb(theme.custom.danger) +
          ', 0.2)',
      },
      '&--rose': {
        color: theme.palette.common.white,
        background:
          'linear-gradient(60deg, ' +
          theme.custom.linear.roseFirst +
          ', ' +
          theme.custom.linear.roseSecond +
          ')',
        boxShadow:
          '0 4px 20px 0 rgba(' +
          hexToRgb(theme.palette.common.black) +
          ', 0.14), 0 7px 10px -5px rgba(' +
          hexToRgb(theme.custom.rose) +
          ', 0.4)',
      },
    },
    '&__body': {
      position: 'relative',
    },
    '&__footer': {
      '&--space': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    '&__title': {
      small: {
        fontSize: '65%',
        fontWeight: '400',
      },
    },
  },
  '.card__icon': {
    float: 'left',
    padding: '15px',
    marginTop: '-20px',
    marginRight: '15px',
    borderRadius: '3px',
    '&--primary': {
      color: theme.palette.common.white,
      background:
        'linear-gradient(60deg, ' +
        theme.custom.linear.primaryFirst +
        ', ' +
        theme.custom.linear.primarySecond +
        ')',
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(theme.custom.primary) +
        ', 0.28), 0 4px 20px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.12), 0 7px 8px -5px rgba(' +
        hexToRgb(theme.custom.primary) +
        ', 0.2)',
    },
    '&--info': {
      color: theme.palette.common.white,
      background:
        'linear-gradient(60deg, ' +
        theme.custom.linear.infoFirst +
        ', ' +
        theme.custom.linear.infoSecond +
        ')',
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(theme.custom.info) +
        ', 0.28), 0 4px 20px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.12), 0 7px 8px -5px rgba(' +
        hexToRgb(theme.custom.info) +
        ', 0.2)',
    },
    '&--success': {
      color: theme.palette.common.white,
      background:
        'linear-gradient(60deg, ' +
        theme.custom.linear.successFirst +
        ', ' +
        theme.custom.linear.successSecond +
        ')',
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(theme.custom.success) +
        ', 0.28), 0 4px 20px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.12), 0 7px 8px -5px rgba(' +
        hexToRgb(theme.custom.success) +
        ', 0.2)',
    },
    '&--warning': {
      color: theme.palette.common.white,
      background:
        'linear-gradient(60deg, ' +
        theme.custom.linear.warningFirst +
        ', ' +
        theme.custom.linear.warningSecond +
        ')',
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(theme.custom.warning) +
        ', 0.28), 0 4px 20px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.12), 0 7px 8px -5px rgba(' +
        hexToRgb(theme.custom.warning) +
        ', 0.2)',
    },
    '&--danger': {
      color: theme.palette.common.white,
      background:
        'linear-gradient(60deg, ' +
        theme.custom.linear.dangerFirst +
        ', ' +
        theme.custom.linear.dangerSecond +
        ')',
      boxShadow:
        '0 12px 20px -10px rgba(' +
        hexToRgb(theme.custom.danger) +
        ', 0.28), 0 4px 20px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.12), 0 7px 8px -5px rgba(' +
        hexToRgb(theme.custom.danger) +
        ', 0.2)',
    },
    '&--rose': {
      color: theme.palette.common.white,
      background:
        'linear-gradient(60deg, ' +
        theme.custom.linear.roseFirst +
        ', ' +
        theme.custom.linear.roseSecond +
        ')',
      boxShadow:
        '0 4px 20px 0 rgba(' +
        hexToRgb(theme.palette.common.black) +
        ', 0.14), 0 7px 10px -5px rgba(' +
        hexToRgb(theme.custom.rose) +
        ', 0.4)',
    },
    '.fab, .fas, .far, .fal, svg, .material-icons': {
      width: '24px',
      height: '24px',
      fontSize: '24px',
      lineHeight: '24px',
      margin: '5px',
      verticalAlign: 'middle',
    },
  },
  '.checkbox--root': {
    padding: '14px',
  },
  '.checkbox': {
    color: theme.custom.primary + ' !important',
  },
  '.checkbox--unchecked-icon': {
    width: 0,
    height: 0,
    padding: '9px',
    border: '1px solid ' + theme.palette.grey[400],
    borderRadius: '3px',
  },
  '.checkbox--checked-icon': {
    width: '20px',
    height: '20px',
    border: '1px solid ' + theme.palette.grey[400],
    borderRadius: '3px',
  },
  '.radio-root': {
    padding: '12px',
  },
  '.radio': {
    color: theme.custom.primary + ' !important',
  },
  '.radio--unchecked-icon': {
    width: 0,
    height: 0,
    padding: '7px',
    border: '1px solid ' + theme.palette.grey[400],
    borderRadius: '50%',
  },
  '.radio--checked-icon': {
    width: '16px',
    height: '16px',
    border: '1px solid ' + theme.custom.primary,
    borderRadius: '50%',
  },
  '.label__root': {
    marginLeft: '-9px',
  },
  '.label': {
    cursor: 'pointer',
    color: theme.custom.grayIcon,
    fontSize: '14px',
  },
  '.label--disabled': {
    a: {
      opacity: theme.palette.mode === 'light' ? 0.38 : 1,
    },
    '.checkbox--unchecked-icon, .checkbox--checked-icon, .radio--unchecked-icon, .radio--checked-icon':
      {
        opacity: 0.26,
      },
  },
  '.dropdown': {
    minWidth: '160px',
  },
  '.dropdown__item': {
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    transition: 'all 0.15s linear',
  },
  '.icon__arrow': {
    marginTop: '11px',
    position: 'absolute',
    right: '18px',
    marginLeft: '2px',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    transition: 'all 0.15s ease-in',
    '&.active': {
      transform: 'rotate(180deg)',
    },
  },
  '.txt': {
    '&--primary': {
      color: theme.custom.primary,
    },
    '&--info': {
      color: theme.custom.info,
    },
    '&--success': {
      color: theme.custom.success,
    },
    '&--warning': {
      color: theme.custom.warning,
    },
    '&--danger': {
      color: theme.custom.danger,
    },
    '&--rose': {
      color: theme.custom.rose,
    },
  },
}));
