import React from 'react';

// libraries
import classNames from 'classnames';

// components
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Icon from '@mui/material/Icon';

// styles
import { InfoAreaStyled } from 'assets/styled/cstyled/infoAreaStyled';

const InfoArea = ({
  title,
  description,
  icon,
  iconColor,
  vertical,
  className,
}: {
  title: string;
  description: string;
  icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  iconColor: string;
  vertical?: boolean;
  className: any;
}) => {
  const infoAreaClasses = classNames({
    'info__area--vertical': vertical,
    [className]: className !== undefined,
  });
  const iconWrapper = classNames({
    icon__wrapper: true,
    ['icon--' + iconColor]: true,
  });
  const iconClasses = classNames({
    icon: true,
    'icon--vertical': vertical,
  });

  let iconResult = null;
  switch (typeof icon) {
    case 'string':
      iconResult = <Icon className={iconClasses}>{icon}</Icon>;
      break;
    default:
      // @ts-ignore
      const ObjIcon = icon;
      iconResult = <ObjIcon className={iconClasses} />;
      break;
  }

  return (
    <InfoAreaStyled className={infoAreaClasses}>
      <div className={iconWrapper}>{iconResult}</div>
      <div className="info__area--description-wrapper">
        <h4 className="info__area--title">{title}</h4>
        <div className="info__area--description">{description}</div>
      </div>
    </InfoAreaStyled>
  );
};

export default React.memo(InfoArea);
