import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

// hooks
import { useAppDispatch, useAppSelector } from 'hooks';

// store
import { setError } from 'store/slices/errorSlice';
import { setCredential } from 'store/slices/credentialSlice';

// libraries
import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import AppleLoginWithButton from 'react-apple-login';
// @ts-ignore
import FacebookLoginWithButton from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLoginWithButton from 'react-google-login';
import momentTZ from 'moment-timezone';
import _ from 'lodash';

// services
import * as userService from 'services/user.service';

// utils
import { customToast } from 'utils/toast';

// layouts
import DefaultLayout from 'layouts/DefaultLayout';

// components
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Timeline from '@mui/icons-material/Timeline';
import Code from '@mui/icons-material/Code';
import Group from '@mui/icons-material/Group';
import Email from '@mui/icons-material/Email';
import Face from '@mui/icons-material/Face';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Check from '@mui/icons-material/Check';
import InfoArea from 'components/InfoArea';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';

// styles
import { RegisterStyled } from 'assets/styled/pstyled/registerStyled';

// interfaces
import { CredentialType } from 'types/credentialType';
import { IRegisterFullFormInputs } from 'types/formType';
import { UserFullInfoType } from 'types/userType';

// others
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_REFRESH_TOKEN_KEY,
} from 'constants/common';
import { APP_ROUTES } from 'constants/app-routes';
import bgRegister from 'assets/images/background/background-first.jpg';

const Register = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const error = useAppSelector((state) => state.error, shallowEqual);
  const auth = useAppSelector((state) => state.auth, shallowEqual);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const schema = yup.object().shape({
    firstname: yup.string().required(t('FIRSTNAME_REQUIRED')),
    lastname: yup.string().required(t('LASTNAME_REQUIRED')),
    email: yup.string().required(t('EMAIL_REQUIRED')).email(t('EMAIL_INVALID')),
    password: yup
      .string()
      .required(t('PASSWORD_REQUIRED'))
      .min(5, t('PASSWORD_INVALID')),
    confirmPassword: yup
      .string()
      .required(t('CONFIRM_PASSWORD_REQUIRED'))
      .oneOf([yup.ref('password'), null], t('CONFIRM_PASSWORD_NOT_MATCH')),
    acceptTerms: yup.bool().required().oneOf([true], t('ACCEPT_TEMP_REQUIRED')),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
  } = useForm<IRegisterFullFormInputs>({
    // @ts-ignore
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      acceptTerms: false,
    },
  });

  // @ts-ignore
  const { from } = location.state || { from: { pathname: APP_ROUTES.LOGIN } };

  const saveCookie = (data: CredentialType) => {
    const accessObj = decode(data.accessToken) || {};
    const refreshObj = decode(data.refreshToken) || {};
    Cookies.set(STORAGE_ACCESS_TOKEN_KEY, data.accessToken, {
      path: '/',
      secure: true,
      // @ts-ignore
      expires: new Date(accessObj.exp * 1000),
    });
    Cookies.set(STORAGE_REFRESH_TOKEN_KEY, data.refreshToken, {
      path: '/',
      secure: true,
      // @ts-ignore
      expires: new Date(refreshObj.exp * 1000),
    });
  };

  const saveAuth = (data: UserFullInfoType) => {
    saveCookie({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
    });
    dispatch(
      setCredential({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    );
  };

  const doRegister = async (data: IRegisterFullFormInputs) => {
    if (!isSubmiting) {
      if (!_.isEmpty(error)) {
        dispatch(setError(null));
      }
      const { acceptTerms, confirmPassword, ...newData } = data;
      newData.timezone = momentTZ.tz.guess(true);
      setIsSubmiting(true);
      try {
        await userService.register(newData);
        history.replace(from);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const message = err.response?.data?.message;
          if (message.length) {
            message.forEach((ms: string) => {
              customToast('error', ms);
            });
          } else {
            customToast('error', message);
          }
        } else {
          customToast('error', t('UNKNOWN_ERROR'));
        }
        setIsSubmiting(false);
      }
    }
  };

  const appleCallback = async (response: any) => {
    if (!_.isEmpty(response.error)) {
      return customToast(
        'error',
        t('AUTHENTICATION_FAIL', { social: 'Apple' })
      );
    }
    generalCallback('apple', response.authorization?.id_token);
  };

  const facebookCallback = async (response: any) => {
    if (response.status) {
      return customToast(
        'error',
        t('AUTHENTICATION_FAIL', { social: 'Facebook' })
      );
    }
    generalCallback('facebook', response.accessToken);
  };

  const googleCallback = (response: any) => {
    if (
      !_.isEmpty(response.error) &&
      response.error === 'idpiframe_initialization_failed'
    ) {
      return;
    } else if (!_.isEmpty(response.error)) {
      return customToast(
        'error',
        t('AUTHENTICATION_FAIL', { social: 'Google' })
      );
    }
    generalCallback('google', response.tokenId);
  };

  const generalCallback = async (type: string, token: string) => {
    if (!_.isEmpty(error)) {
      dispatch(setError(null));
    }
    try {
      const res = await userService.social({
        type,
        token,
        timezone: momentTZ.tz.guess(true),
      });
      saveAuth(res);
      history.replace(from);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const message = err.response?.data?.message;
        if (message.length) {
          message.forEach((ms: string) => {
            customToast('error', ms);
          });
        } else {
          customToast('error', message);
        }
      } else {
        customToast('error', t('UNKNOWN_ERROR'));
      }
    }
  };

  return auth && !auth.loggedIn ? (
    <DefaultLayout backgroundImage={bgRegister}>
      <RegisterStyled className="page page__register page--padding">
        <div className="container">
          <Grid container justifyContent="center" className="grid">
            <Grid item xs={12} sm={10} md={12} lg={10}>
              <div className="card card__specific card__register">
                <h2 className="card__title txt--center">{t('REGISTER')}</h2>
                <div className="card__body">
                  <Grid container justifyContent="center" className="grid">
                    <Grid item xs={12} sm={12} md={5}>
                      <InfoArea
                        title={t('STATISTICS')}
                        description={t('STATISTICS_DESCRIPTION')}
                        icon={Timeline}
                        iconColor="rose"
                        className="info__area"
                      />
                      <InfoArea
                        title={t('ARCHITECTURE_PLATFORM')}
                        description={t('ARCHITECTURE_PLATFORM_DESCRIPTION')}
                        icon={Code}
                        iconColor="primary"
                        className="info__area"
                      />
                      <InfoArea
                        title={t('TOOL_FOR_USER')}
                        description={t('TOOL_FOR_USER_DESCRIPTION')}
                        icon={Group}
                        iconColor="info"
                        className="info__area"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <div className="txt--center">
                        {
                          // @ts-ignore
                          process.env.REACT_APP_APPLE_CLIENT_ID ? (
                            <AppleLoginWithButton
                              clientId={
                                // @ts-ignore
                                process.env.REACT_APP_APPLE_CLIENT_ID
                              }
                              responseType="code id_token"
                              responseMode="fragment"
                              // @ts-ignore
                              redirectURI={
                                // @ts-ignore
                                process.env.REACT_APP_APPLE_REDIRECT_URL
                              }
                              usePopup={true}
                              render={(renderProps: any) => (
                                <CustomButton
                                  justIcon
                                  round
                                  color="apple"
                                  disabled
                                  onClick={renderProps.onClick}
                                >
                                  <i className="fab fa-apple" />
                                </CustomButton>
                              )}
                              callback={appleCallback}
                            />
                          ) : (
                            ''
                          )
                        }{' '}
                        {
                          // @ts-ignore
                          process.env.REACT_APP_FB_CLIENT_ID ? (
                            <FacebookLoginWithButton
                              // @ts-ignore
                              appId={process.env.REACT_APP_FB_CLIENT_ID}
                              autoLoad={false}
                              fields="name,first_name,last_name,email,picture"
                              render={(renderProps: any) => (
                                <CustomButton
                                  justIcon
                                  round
                                  color="facebook"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="fab fa-facebook-f" />
                                </CustomButton>
                              )}
                              callback={facebookCallback}
                            />
                          ) : (
                            ''
                          )
                        }{' '}
                        {
                          // @ts-ignore
                          process.env.REACT_APP_FB_CLIENT_ID ? (
                            <GoogleLoginWithButton
                              // @ts-ignore
                              clientId={
                                // @ts-ignore
                                process.env.REACT_APP_GG_CLIENT_ID
                              }
                              cookiePolicy={'single_host_origin'}
                              render={(renderProps: any) => (
                                <CustomButton
                                  justIcon
                                  round
                                  color="google"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="fab fa-google" />
                                </CustomButton>
                              )}
                              onSuccess={googleCallback}
                              onFailure={googleCallback}
                            />
                          ) : (
                            ''
                          )
                        }
                        <h4>{t('OR_BE_CLASSICAL')}</h4>
                      </div>
                      <form onSubmit={handleSubmit(doRegister)}>
                        <Controller
                          name="firstname"
                          control={control}
                          render={({ field }) => (
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              error={
                                dirtyFields.firstname && errors.firstname
                                  ? true
                                  : false
                              }
                              success={
                                dirtyFields.firstname && !errors.firstname
                                  ? true
                                  : false
                              }
                              inputProps={{
                                ...field,
                                placeholder: t('FIRSTNAME'),
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Face />
                                  </InputAdornment>
                                ),
                              }}
                              helpText={errors.firstname?.message}
                            />
                          )}
                        />
                        <Controller
                          name="lastname"
                          control={control}
                          render={({ field }) => (
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              error={
                                dirtyFields.lastname && errors.lastname
                                  ? true
                                  : false
                              }
                              success={
                                dirtyFields.lastname && !errors.lastname
                                  ? true
                                  : false
                              }
                              inputProps={{
                                ...field,
                                placeholder: t('LASTNAME'),
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SupervisedUserCircleIcon />
                                  </InputAdornment>
                                ),
                              }}
                              helpText={errors.lastname?.message}
                            />
                          )}
                        />
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              error={
                                dirtyFields.email && errors.email ? true : false
                              }
                              success={
                                dirtyFields.email && !errors.email
                                  ? true
                                  : false
                              }
                              inputProps={{
                                ...field,
                                placeholder: t('EMAIL'),
                                type: 'email',
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Email />
                                  </InputAdornment>
                                ),
                              }}
                              helpText={errors.email?.message}
                            />
                          )}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          error={
                            dirtyFields.password && errors.password
                              ? true
                              : false
                          }
                          success={
                            dirtyFields.password && !errors.password
                              ? true
                              : false
                          }
                          inputProps={{
                            ...register('password'),
                            placeholder: t('PASSWORD'),
                            type: 'password',
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>lock_outline</Icon>
                              </InputAdornment>
                            ),
                            autoComplete: 'off',
                          }}
                          helpText={errors.password?.message}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          error={
                            dirtyFields.confirmPassword &&
                            errors.confirmPassword
                              ? true
                              : false
                          }
                          success={
                            dirtyFields.confirmPassword &&
                            !errors.confirmPassword
                              ? true
                              : false
                          }
                          inputProps={{
                            ...register('confirmPassword'),
                            placeholder: t('CONFIRM_PASSWORD'),
                            type: 'password',
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon>lock_outline</Icon>
                              </InputAdornment>
                            ),
                            autoComplete: 'off',
                          }}
                          helpText={errors.confirmPassword?.message}
                        />
                        <Controller
                          name="acceptTerms"
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              // disabled
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  icon={
                                    <Check className="checkbox--unchecked-icon" />
                                  }
                                  checkedIcon={
                                    <Check className="checkbox--checked-icon" />
                                  }
                                  classes={{
                                    root: 'checkbox-root',
                                    checked: 'checkbox',
                                  }}
                                />
                              }
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `${t('CONFIRM_TEMP', {
                                      temp: t('TEMP_AND_POLICY'),
                                      link: '#policy',
                                    })}`,
                                  }}
                                ></span>
                              }
                              classes={{
                                root: 'label__root',
                                label: 'label',
                                // disabled: 'label--disabled',
                              }}
                            />
                          )}
                        />
                        <div className="txt--center">
                          <CustomButton
                            round
                            type="submit"
                            color="primary"
                            className={`${
                              !isValid || isSubmiting ? 'btn--disabled' : ''
                            }`}
                          >
                            {t('GET_STARTED')}
                          </CustomButton>
                        </div>
                      </form>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </RegisterStyled>
    </DefaultLayout>
  ) : (
    <Redirect to={{ pathname: from.pathname }} />
  );
};

export default React.memo(Register);
