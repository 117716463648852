import axios from 'axios';
import { API_ROUTES } from 'constants/api-routes';
import {
  IRegisterFormInputs,
  ILoginFormInputs,
  ILoginSocialFormInputs,
  ILogoutInputs,
} from 'types/formType';
import { UserFullInfoType } from 'types/userType';

const register = async (data: IRegisterFormInputs): Promise<any> => {
  const response = await axios.post(API_ROUTES.AUTH_REGISTER, data);
  return response?.data;
};

const login = async (data: ILoginFormInputs): Promise<UserFullInfoType> => {
  const response = await axios.post(API_ROUTES.AUTH_LOGIN, data);
  return response?.data;
};

const social = async (
  data: ILoginSocialFormInputs
): Promise<UserFullInfoType> => {
  const response = await axios.post(API_ROUTES.AUTH_LOGIN_SOCIAL, data);
  return response?.data;
};

const logout = async (data: ILogoutInputs = {}): Promise<any> => {
  const response = await axios.post(API_ROUTES.AUTH_LOGOUT, data);
  return response?.data;
};

export { register, login, social, logout };
